import React, { Component } from 'react';
import './DocumentPreview.css';
import { withTranslation } from 'react-i18next';
import { Button, InputGroup, ListGroup, Modal, Toast, FormControl, Tabs, Tab, Col, Nav, Row, ProgressBar } from "react-bootstrap";
import iconAttachedDocs from '../../assets/icons/attachedDocs.svg';
import iconBackButton from '../../assets/icons/backButton.svg';
import iconNextButton from '../../assets/icons/nextButton.svg';
import iconArrowDown from '../../assets/icons/arrow_down.svg';
import iconArrowUp from '../../assets/icons/arrow_up.svg';
import iconQuestionBox from '../../assets/icons/question_box.svg';
import squareEyeBox from '../../assets/icons/square-eye.svg';
import squareDocBox from '../../assets/icons/square-doc.svg';
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Utils from '../../services/Utils';
import SigningProcessModal from '../Modals/SigningProcessModal';
import RejectingProcessModal from '../Modals/RejectingProcessModal';
import LoadingCircle from '../LoadingCircle';
import { getVidCloudPrivUrl, getVidCloudMgmtUrl } from '../../services/environment/environmentService';
import { CustomModal } from '../CustomModal/CustomModal';
import { REJECTION_REASON_MIN_CHARACTERS } from '../../assets/constants';
import { NEBULA, REDTRUST, REDTRUST_CERTIFICATE_PIN_KEY, KEYVAULT, KEYVAULT_CERTIFICATE_PIN_KEY, FIRMAREMOTA, SOFTWARE, SOFTWARE_CERTIFICATE_PIN_KEY, UANATACA, UANATACA_CERTIFICATE_PIN_KEY, SWISSCOM, NEBULA_CERTIFICATE_PIN_KEY, OTP, VALIDATEDID_CERTIFICATE_PIN_KEY, VALIDATEDID, PREVIEW } from '../../services/signatureMethods/constants';
import {
  generateRedTrustUri,
  postPrivSignature,
  generateKeyVaultUri,
  generateKeyVaultSendOTPUri,
  deleteManagementSignature,
  generateFirmaRemotaOTPUri,
  generateFirmaRemotaSessionUri,
  generateFirmaRemotaUri,
  putManagementSignature,
  putPrivSignature,
  generateUanatacaUri,
  generateSoftwareUri,
  generateSwisscomUri,
  postManagementSignature,
  generateNebulaAuthMiddleUri,
  generateNebulaAuthEndUri,
  generateNebulaUri,
  generateNebulaAuthUri,
  generateUanatacaOtpUri,
  generateValidatedIdOtpUri,
  generateValidatedIdUri
} from '../../services/signatureMethods/customServiceSignature';
import KeyVaultSignature from '../KeyVaultSignature/KeyVaultSignature.component';
import RedTrustSignature from '../RedTrustSignature/RedTrustSignature.component';
import FirmaRemota from '../FirmaRemota/FirmaRemota.component';
import UanatacaSignature from '../UanatacaSignature/UanatacaSignature.component';
import SoftwareSignature from '../SotwareSignature/SoftwareSignature.component';
import SwisscomSignature from '../SwisscomSignature/SwisscomSignature.component';
import NebulaSignature from '../NebulaSignature/NebulaSignature.component';
import { checkShowNoPinConfirmationModal } from '../../services/signatureMethods/helperService';
import ValidatedIdSignature from '../ValidatedIdSignature/ValidatedIdSignature.component';

const ipLocation = require("iplocation");
const publicIp = require('public-ip');
const { detect } = require('detect-browser');
const browser = detect();

class DocumentPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
      environment: sessionStorage.getItem('environment'),
      language: localStorage.getItem('i18nextLng'),
      showSignModal: false,
      showRejectModal: false,
      time: 0,
      showKeyVaultModal: false,
      showFirmaRemotaModal: false,
      showRedTrustModal: false,
      showSoftwareModal: false,
      showUanatacaModalPin: false,
      showUanatacaModalOtp: false,
      showValidatedIdModalPin: false,
      showValidatedIdModalOtp: false,
      showSwisscomModal: false,
      showAutofirmaModal: false,
      showNebulaModal: false,
      showSwisscomConfirmationModal: false,
      showNoPinConfirmationModal: false,
      certificateSoftwarePin: '',
      certificateKeyVaultPin: '',
      certificateFirmaRemotaPin: '',
      certificateRedTrustPin: '',
      certificateUanatacaPin: '',
      certificateUanatacaOtp: '',
      certificateValidatedIdPin: '',
      certificateValidatedIdOtp: '',
      certificateNebulaPin: '',
      certificateSelected: {},
      loadingSignatureProcess: false,
      loadingRejectingProcess: false,
      sessionOpened: false,
      showError: false,
      errorData: '',
      errorType: '',
      rejectReason: '',
      actualPage: 1,
      actualAttachedPage: 1,
      sideMenuOption: 1,
      tabKey: 0,
      certTitleTabs: [],
      swisscomRedirectURL: "",
      swisscomRequestID: "",
      loadingSwisscomRequest: false,
      loadingSignatureProcessAutofirma: false,
      loadingSwisscomCheck: false,
      swisscomError: '',
      autofirmaError: '',
      autofirmaPercentage: 0,
      clientIp: '',
      ipData: '',
      showSignatureReason: true,
      vuserCertsFiltered: [],
      nebulaStep1: false,
      nebulaStep2: false,
      nebulaStep3: false,
      nebulaPinRequired: true,
      isUanatacaQualified: false,
      isValidatedIdQualified: false,
      showHeaderInfo: true,
      accessMethod: sessionStorage.getItem("AccessMethod")
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.actualPage = this.actualPage.bind(this);
    this.actualAttachedPage = this.actualAttachedPage.bind(this);
    this.handleAuthenticatorChange = this.handleAuthenticatorChange.bind(this);
    this.sendAuthenticator = this.sendAuthenticator.bind(this);
    this.authenticators = [];
    this.handleSignatureSuccess = this.handleSignatureSuccess.bind(this);
    this.handleSignatureError = this.handleSignatureError.bind(this);
    this.handleSessionExpiredError = this.handleSessionExpiredError.bind(this);
    this.handleSign = this.handleSign.bind(this);
    this.handleGenericError = this.handleGenericError.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleModalSignatureSuccess = this.handleModalSignatureSuccess.bind(this);
    this.getAuthenticators = this.getAuthenticators.bind(this);
    this.sendChallenge = this.sendChallenge.bind(this);
    this.handleModalSignatureOtpSuccess = this.handleModalSignatureOtpSuccess.bind(this);
    this.handleModalSignatureValidatedIdSuccess = this.handleModalSignatureValidatedIdSuccess.bind(this);
    this.onHeaderInfoClick = this.onHeaderInfoClick.bind(this);
  }

  focusInput(input) {
    /**The stimeout is necesary to wait until the DOM is loaded */
    if (input) setTimeout(function () { input.focus(); }, 1);
  };

  handleModalSignatureSuccess(signatureName, showModalKey, certificatePinKey, certificatePin) {
    this.setState({
      loadingSignatureProcess: true,
      showNoPinConfirmationModal: false,
      [showModalKey]: false,
      [certificatePinKey]: certificatePin
    },
      () => this.signDocSelection(signatureName))
  }

  handleModalSignatureOtpSuccess(signatureName, showModalKeyPin, showModalKeyOtp, certificatePinKey, certificatePin, certificateOtp) {
    const { isUanatacaQualified } = this.state;
    if (isUanatacaQualified) {
      this.setState({ [showModalKeyOtp]: false, showUanatacaModalPin: true, certificateUanatacaOtp: certificateOtp });
      return;
    }

    this.handleModalSignatureSuccess(signatureName, showModalKeyPin, certificatePinKey, certificatePin);
  }

  handleModalSignatureValidatedIdSuccess(signatureName, showModalKeyPin, showModalKeyOtp, certificatePinKey, certificatePin, certificateOtp) {
    const { isValidatedIdQualified } = this.state;
    if (isValidatedIdQualified) {
      this.setState({ [showModalKeyOtp]: false, showValidatedIdModalPin: true, certificateValidatedIdOtp: certificateOtp });
      return;
    }

    this.handleModalSignatureSuccess(signatureName, showModalKeyPin, certificatePinKey, certificatePin);
  }

  handleClose() {
    this.setState({
      showSignModal: false,
      showRejectModal: false,
      showKeyVaultModal: false,
      showFirmaRemotaModal: false,
      showRedTrustModal: false,
      showSoftwareModal: false,
      showUanatacaModalPin: false,
      showUanatacaModalOtp: false,
      showValidatedIdModalPin: false,
      showValidatedIdModalOtp: false,
      showSwisscomModal: false,
      showAutofirmaModal: false,
      showNebulaModal: false,
      showSwisscomConfirmationModal: false,
      showNoPinConfirmationModal: false,
      loadingSignatureProcess: false,
      loadingRejectingProcess: false,
      loadingSwisscomCheck: false,
      loadingSwisscomRequest: false,
      loadingSignatureProcessAutofirma: false,
      certificateSoftwarePin: '',
      certificateKeyVaultPin: '',
      certificateFirmaRemotaPin: '',
      certificateRedTrustPin: '',
      certificateUanatacaPin: '',
      certificateUanatacaOtp: '',
      certificateValidatedIdPin: '',
      certificateValidatedIdOtp: '',
      certificateNebulaPin: '',
      nebulaStep1: false,
      nebulaStep2: false,
      nebulaStep3: false,
      NBauthenticator: '',
      NBChallenge: '',
      showAdditionalSignaturesDisclaimer: false,
      showChallengeError: false,
      textAdditionalSignaturesDisclaimer: '',
      titleAdditionalSignaturesDisclaimer: ''
    })
  }

  doLogOut() {
    sessionStorage.clear();
    this.stopTimer();
    this.setState({ redirect: true })
  }

  startTimer() {
    this.timer = setInterval(() => this.setState({
      time: this.state.time + 1
    }), 60000);
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  loadUserData() {
    this.setState({ loading: true });
    const _this = this;
    const { oauthTokenB64 } = this.state;


    var config = {
      headers: {
        'Authorization': 'Bearer ' + oauthTokenB64
      }
    };
    return new Promise(function (resolve, reject) {
      axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
        .then(res => {
          resolve(res.data);
        })
        .catch(function (error) {
          if (error?.response?.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            _this.setState({ sessionExpired: true });
            _this.startTimer();
          } else {
            _this.setState({
              errorType: error.response.status,
              errorData: error.response.data,
              showError: true
            });
          }
          reject(error);
        });
    });
  }

  getUserCertificates() {
    const _this = this;
    const { t } = this.props;
    var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };
    const { userData, userCertificatesAuth } = this.state;

    return new Promise(function (resolve, reject) {
      axios.get(getVidCloudMgmtUrl() + '/vidmobileusers/' + userData.legalid + '/certs', config)
        .then(res => {
          resolve(res.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            _this.setState({ sessionExpired: true });
            _this.startTimer();
          }
          if (error.response.status === 404) {
             // Show Autofirma cert by default
             if (userCertificatesAuth?.Local) {
              const certificate = {
                CertType: "Autofirma"
              };
              const certificatesList = <>
                <ListGroup.Item
                  key={"Autofirma"}
                  action
                  variant="light"
                  onClick={() => _this.handleCertSelection(certificate)}
                >
                  <ul className="list-container">
                    <li>{t('general.alias')}: Autofirma</li>
                    <li>{t('general.type')}: Autofirma </li>
                    <li>{t('general.expiration-date')}: --</li>
                  </ul>
                </ListGroup.Item>
              </>
              _this.setState({ certificatesList });
              return;
            }
            if (!userCertificatesAuth || (!userCertificatesAuth.Swisscom && !userCertificatesAuth.Autofirma)) {
              _this.setState({ certificatesList: <p>{t('documents.cert-type-selection-empty')}</p> });
              return;
            }
            if (userCertificatesAuth.Swisscom || userCertificatesAuth.Autofirma)
              return resolve([]);
          }

          _this.setState({
            errorType: error.response.status,
            errorData: error.response.data,
            showError: true
          });

          reject(error);
        });
    });
  }

  loadViDMobileUserCertificateTypes() {
    const _this = this;
    var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };

    return new Promise(function (resolve, reject) {
      axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/certstype', config)
        .then(res => {
          resolve(res.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            _this.setState({ sessionExpired: true });
            _this.startTimer();
          } else {
            _this.setState({
              errorType: error.response.status,
              errorData: error.response.data,
              showError: true
            });
          }
          reject(error);
        });
    });
  };

  loadViDMobileUserCertificateList() {
    const { t } = this.props;
    const _this = this;

    this.loadUserData()
      .then(res => {
        if (res) {
          _this.setState({ userData: res });
          _this.loadViDMobileUserCertificateTypes()
            .then(res => {
              res.Nebula = true;
              _this.setState({ userCertificatesAuth: res });
              _this.getUserCertificates()
                .then(res => {
                  if (_this.state.userCertificatesAuth === null) {
                    var ViDMobileUserAllowSWCerts = true;
                  } else {
                    ViDMobileUserAllowSWCerts = _this.state.userCertificatesAuth.Software;
                    var ViDMobileUserAllowFirmaRemotaCerts = _this.state.userCertificatesAuth.FirmaRemota;
                    var ViDMobileUserAllowKeyVaultCerts = _this.state.userCertificatesAuth.KeyVault;
                    var ViDMobileUserAllowRTCerts = _this.state.userCertificatesAuth.RedTrust;
                    var ViDMobileUserAllowUTCerts = _this.state.userCertificatesAuth.Uanataca;
                    var ViDMobileUserAllowSwisscomCerts = _this.state.userCertificatesAuth.Swisscom;
                    var ViDMobileUserAllowAutofirma = _this.state.userCertificatesAuth.Local;
                    var ViDMobileUserAllowNebulaCerts = _this.state.userCertificatesAuth.Nebula;
                    var ViDMobileUserAllowValidatedIdCerts = _this.state.userCertificatesAuth.ValidatedID;
                  }
                  var userCertsFiltered = res.reduce(function (userCertsAllowed, certificate) {
                    if ((certificate.CertType === "FirmaRemota" && ViDMobileUserAllowFirmaRemotaCerts) ||
                      (certificate.CertType === "KeyVault" && ViDMobileUserAllowKeyVaultCerts) ||
                      (certificate.CertType === "Software" && ViDMobileUserAllowSWCerts) ||
                      (certificate.CertType === "RedTrust" && ViDMobileUserAllowRTCerts) ||
                      (certificate.CertType === "Uanataca" && ViDMobileUserAllowUTCerts) ||
                      (certificate.CertType === "Nebula" && ViDMobileUserAllowNebulaCerts) ||
                      (certificate.CertType === "ValidatedID" && ViDMobileUserAllowValidatedIdCerts)) {
                      userCertsAllowed.push(certificate);
                    }
                    return userCertsAllowed;
                  }, []);
                  if (ViDMobileUserAllowSwisscomCerts) {
                    userCertsFiltered.push({
                      CertType: "Swisscom",
                      ExpirationDate: "-",
                      certalias: "Swisscom"
                    });
                  }
                  if (ViDMobileUserAllowAutofirma) {
                    userCertsFiltered.push({
                      CertType: "Autofirma",
                      ExpirationDate: "-",
                      certalias: "Autofirma"
                    });
                  }
                  var vuserCertsFiltered = userCertsFiltered;
                  if (_this.props.selectedDoc.SignerDTO.CentralizedCertificateInfo) {
                    // return all certificates if doc doesnt have any assigned or return only assigned certificate
                    if (_this.props.selectedDoc.SignerDTO.CentralizedCertificateInfo.Guid !== null && userCertsFiltered) {
                      vuserCertsFiltered = userCertsFiltered.reduce(function (userCertsAllowed, certificate) {
                        if ( !_this.props.selectedDoc.SignerDTO.CentralizedCertificateInfo.Guid || (certificate.GUID === _this.props.selectedDoc.SignerDTO.CentralizedCertificateInfo.Guid)) {
                          userCertsAllowed.push(certificate);
                        }
                        return userCertsAllowed;
                      }, []);
                    }
                  }

                  if (typeof vuserCertsFiltered !== 'undefined' && vuserCertsFiltered.length > 0) {
                    var certificatesList = vuserCertsFiltered.map(certificate => {
                      if (certificate.CertType === "ValidatedID") {
                        return <ListGroup.Item
                          key={certificate.GUID + certificate.certalias}
                          action
                          variant="light"
                          onClick={() => this.handleCertSelection(certificate)}
                        >
                          <ul className="list-container">
                            <li>{t('general.alias')}: {certificate.DN ? certificate.DN : certificate.certalias}</li>
                            <li>{t('general.type')}: Validated ID</li>
                            <li>{t('general.expiration-date')}: {certificate.ExpirationDate}</li>
                          </ul>
                        </ListGroup.Item>
                      }
                      if (certificate.CertType === "FirmaRemota") {
                        return <ListGroup.Item
                          key={certificate.GUID + certificate.certalias}
                          action
                          variant="light"
                          onClick={() => this.handleCertSelection(certificate)}
                        >
                          <ul className="list-container">
                            <li>{t('general.alias')}: {certificate.certalias}</li>
                            <li>{t('general.type')}: itAgile</li>
                            <li>{t('general.expiration-date')}: {certificate.ExpirationDate}</li>
                          </ul>
                        </ListGroup.Item>
                      }
                      else if (certificate.certalias !== "Swisscom" && certificate.certalias !== "Autofirma") {
                        return <ListGroup.Item
                          key={certificate.GUID + certificate.certalias}
                          action
                          variant="light"
                          onClick={() => this.handleCertSelection(certificate)}
                        >
                          <ul className="list-container">
                            <li>{t('general.alias')}: {certificate.AuthenticationType === 'OTP' && certificate.DN} {certificate.AuthenticationType !== 'OTP' && certificate.certalias}</li>
                            <li>{t('general.type')}: {certificate.CertType}</li>
                            <li>{t('general.expiration-date')}: {certificate.ExpirationDate}</li>
                          </ul>
                        </ListGroup.Item>
                      }
                      else {
                        return <ListGroup.Item
                          key={certificate.GUID + certificate.certalias}
                          action
                          variant="light"
                          onClick={() => this.handleCertSelection(certificate)}
                        >
                          <ul className="list-container">
                            <li>{certificate.certalias}</li>
                          </ul>
                        </ListGroup.Item>
                      }
                    });
                    _this.setState({ vuserCertsFiltered: vuserCertsFiltered, certificatesList: certificatesList });
                  }
                  else {
                    this.setState({
                      certificatesList: <p>{t('documents.cert-type-selection-empty')}</p>,
                      showSignModal: true
                    });
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log('no user data')
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  handleCertSelection(certificate) {
    var showKeyVaultModal = false;
    var showFirmaRemotaModal = false;
    var showSoftwareModal = false;
    var showRedTrustModal = false;
    var showSwisscomModal = false;
    var showAutofirmaModal = false;
    var showNebulaModal = false;
    var showNoPinConfirmationModal = false;
    if (checkShowNoPinConfirmationModal(certificate)) {
      showNoPinConfirmationModal = true;
      this.setState({ nebulaPinRequired: false });
    }
    else {
      if (certificate.CertType === "KeyVault" && certificate.AuthenticationType === "PIN") {
        showKeyVaultModal = true;
      }
      if (certificate.CertType === "KeyVault" && certificate.AuthenticationType === "SMS") {
        showKeyVaultModal = true;
        this.keyVaultSendOTP(certificate);
      }
      if (certificate.CertType === "FirmaRemota" && certificate.AuthenticationType === "APP") {
        showFirmaRemotaModal = true;
      }
      if (certificate.CertType === "FirmaRemota" && certificate.AuthenticationType === "SMS") {
        showFirmaRemotaModal = true;
        this.firmaRemotaSendOTP();
      }
      if (certificate.CertType === "Software") {
        showSoftwareModal = true;
      }
      if (certificate.CertType === "RedTrust") {
        showRedTrustModal = true;
      }
      if (certificate.CertType === "Uanataca") {
        this.isQualifiedCertificate(certificate.AuthenticationType);
      }
      if (certificate.CertType === "ValidatedID") {
        this.isValidatedIdCertificateQualified(certificate.AuthenticationType);
      }
      if (certificate.CertType === "Swisscom") {
        showSwisscomModal = true;
      }
      if (certificate.CertType === "Autofirma") {
        showAutofirmaModal = true;
        this.signDocAutofirma();
      }
      if (certificate.CertType === "Nebula") {
        this.getAuthenticators();
        showNebulaModal = true;
      }
    }
    this.setState({
      showKeyVaultModal: showKeyVaultModal,
      showFirmaRemotaModal: showFirmaRemotaModal,
      showSoftwareModal: showSoftwareModal,
      showRedTrustModal: showRedTrustModal,
      showSwisscomModal: showSwisscomModal,
      showAutofirmaModal: showAutofirmaModal,
      showNebulaModal: showNebulaModal,
      showNoPinConfirmationModal: showNoPinConfirmationModal,
      showSignModal: false,
      certificateSelected: certificate
    });
  }

  isQualifiedCertificate(authenticationType) {
    const _this = this;
    if (authenticationType === OTP) {
      _this.setState({ isUanatacaQualified: true });
      const { oauthTokenB64 } = this.state;
      var viDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

      postManagementSignature(generateUanatacaOtpUri(viDMobileUserGUID), '', oauthTokenB64)
        .then(res => {
          _this.setState({ showUanatacaModalOtp: true });
        })
        .catch(function (error) {
          _this.handleSignatureError(error, UANATACA_CERTIFICATE_PIN_KEY);
        });

      return;
    }

    _this.setState({ showUanatacaModalPin: true });
  }

  isValidatedIdCertificateQualified(authenticationType) {
    const _this = this;
    if (authenticationType === OTP) {
      _this.setState({ isValidatedIdQualified: true });
      const { oauthTokenB64 } = this.state;
      var viDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

      postManagementSignature(generateValidatedIdOtpUri(viDMobileUserGUID), '', oauthTokenB64)
        .then(res => {
          _this.setState({ showValidatedIdModalOtp: true });
        })
        .catch(function (error) {
          _this.handleSignatureError(error, VALIDATEDID_CERTIFICATE_PIN_KEY);
        });

      return;
    }

    _this.setState({ showValidatedIdModalPin: true });
  }

  rejectDoc() {
    const _this = this;
    const { oauthTokenB64, rejectReason, accessMethod } = this.state;
    const { SignerDTO, DocGUI } = this.props.selectedDoc;
    this.setState({
      loadingRejectingProcess: true,
      showRejectModal: false,
    });

    axios({
      method: 'post',
      url: getVidCloudPrivUrl() + '/vidmobileuser/signature/' + SignerDTO.SignerGUI + '/rejected',
      data: {
        RejectionReason: rejectReason,
        AccessMethod: accessMethod
      },
      async: true,
      dataType: "json",
      contentType: 'application/json',
      crossDomain: true,
      headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
    })
      .then(res => {
        if (res.status === 204) {
          Utils.removeItemFromSelectedDocsInSessionByGuid(DocGUI);
          _this.setState({ loadingRejectingProcess: false });
          _this.props.showRejectedDocHandler();
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
          _this.setState({ sessionExpired: true });
          _this.startTimer();
        } else {
          _this.setState({
            errorType: error.response.status,
            errorData: error.response.data,
            showError: true,
            loadingRejectingProcess: false
          });
          _this.props.handlePreviouslyRejectedDocError(error, true);
        }
      });
  };

  signDocKeyVault() {
    const _this = this;
    const { oauthTokenB64, certificateSelected, certificateKeyVaultPin, accessMethod} = this.state;
    const { SignerDTO } = this.props.selectedDoc;
    const dataJson = {
      CertGUID: certificateSelected.GUID,
      PIN: certificateKeyVaultPin,
      CertType: certificateSelected.CertType,
      AccessMethod: accessMethod
    };

    postPrivSignature(generateKeyVaultUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
      .then(res => {
        _this.handleSignatureSuccess(res.status, KEYVAULT);
      })
      .catch(function (error) {
        _this.handleSignatureError(error, KEYVAULT_CERTIFICATE_PIN_KEY);
      });
  }

  signDocSoftware() {
    const _this = this;
    const { oauthTokenB64, certificateSoftwarePin, accessMethod } = this.state;
    const { SignerDTO } = this.props.selectedDoc;

    const dataJson = {
      PinAuthentication: certificateSoftwarePin,
      AccessMethod: accessMethod
    };

    postPrivSignature(generateSoftwareUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
      .then(res => {
        _this.handleSignatureSuccess(res.status, SOFTWARE);
      })
      .catch(function (error) {
        _this.handleSignatureError(error, SOFTWARE_CERTIFICATE_PIN_KEY);
      });
  }

  handleNoPinDocSignature() {
    this.setState({
      loadingSignatureProcess: true,
      showNoPinConfirmationModal: false
    });
    const { certificateSelected } = this.state;

    if (certificateSelected.CertType === "KeyVault") {
      this.signDocKeyVault();
    }
    if (certificateSelected.CertType === "FirmaRemota") {
      this.signDocFirmaRemota();
    }
    if (certificateSelected.CertType === "Software") {
      this.signDocSoftware();
    }
    if (certificateSelected.CertType === "RedTrust") {
      this.signDocRedTrust();
    }
    if (certificateSelected.CertType === "Nebula") {
      this.setState({ loadingSignatureProcess: false, showNebulaModal: true });
      this.getAuthenticators();
    }
  }

  firmaRemotaOpenSession() {
    const _this = this;

    const { oauthTokenB64, certificateFirmaRemotaPin } = this.state;

    const dataJson = {
      OTP: certificateFirmaRemotaPin
    };

    putManagementSignature(generateFirmaRemotaSessionUri(), dataJson, oauthTokenB64)
      .then(res => {
        _this.setState({ sessionOpened: true });
      })
      .catch(function (error) {
        _this.handleGenericError(error);

        _this.props.handlePreviouslyRejectedDocError(error, true);
      });
  }

  firmaRemotaCloseSession() {
    const _this = this;

    const { oauthTokenB64, certificateFirmaRemotaPin } = this.state;

    const dataJson = {
      OTP: certificateFirmaRemotaPin
    };

    deleteManagementSignature(generateFirmaRemotaSessionUri(), dataJson, oauthTokenB64)
      .then(res => {
        _this.setState({ certificateFirmaRemotaPin: '' });
      })
      .catch(function (error) {
        _this.handleGenericError(error);
      })
      .finally(() => {
        _this.setState({ certificateFirmaRemotaPin: '' });
      });
  }

  getViDMobileUserGUIDFromOauthToken() {
    const { oauthTokenB64 } = this.state;
    var tokenParts = oauthTokenB64.split(".");
    var jsonTokenInfo = atob(tokenParts[1]);
    var tokenInfo = JSON.parse(jsonTokenInfo);
    return tokenInfo.sub;
  }

  handleSessionExpiredError(status) {
    if (status === 401) {
      /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
      this.setState({ sessionExpired: true });
      this.startTimer();
    }
  }

  handleGenericError(error) {
    this.handleSessionExpiredError(error.response.status);

    if (error.response.status !== 401) {
      this.setState({
        errorType: error.response.status,
        errorData: error.response.data,
        showError: true
      });
    }
  }

  firmaRemotaSendOTP() {
    const _this = this;

    const { oauthTokenB64 } = this.state;
    var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

    putPrivSignature(generateFirmaRemotaOTPUri(ViDMobileUserGUID), "", oauthTokenB64)
      .then(res => {
        //firmaRemota SMS OTP sent
      })
      .catch(function (error) {
        _this.handleGenericError(error);
      });
  }

  keyVaultSendOTP(certificate) {
    const _this = this;

    const { oauthTokenB64, accessMethod } = this.state;
    const dataJson = {
      AccessMethod: accessMethod
    };

    postPrivSignature(generateKeyVaultSendOTPUri(certificate.GUID), dataJson, oauthTokenB64)
      .then(res => {
        //keyVault SMS OTP sent
      })
      .catch(function (error) {
        _this.handleGenericError(error);
      });
  }

  signDocFirmaRemota() {
    const _this = this;
    const { oauthTokenB64, certificateSelected, certificateFirmaRemotaPin, sessionOpened, accessMethod } = this.state;
    const { SignerDTO, DocGUI } = this.props.selectedDoc;

    if (certificateFirmaRemotaPin !== "") {
      this.firmaRemotaOpenSession();
    }

    const dataJson = {
      CertGUID: certificateSelected.GUID,
      PIN: certificateFirmaRemotaPin,
      CertType: certificateSelected.CertType,
      AccessMethod: accessMethod
    };

    postPrivSignature(generateFirmaRemotaUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
      .then(res => {
        if (res.status === 204) {
          if (sessionOpened) {
            _this.firmaRemotaCloseSession();
          }
          Utils.removeItemFromSelectedDocsInSessionByGuid(DocGUI);
          _this.setState({ loadingSignatureProcess: false, certificateFirmaRemotaPin: '' });
          _this.props.showSignedDocHandler();
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
          _this.setState({ sessionExpired: true });
          _this.startTimer();
        } else {
          _this.setState({
            loadingSignatureProcess: false,
            errorType: error.response.status,
            errorData: error.response.data,
            showError: true,
            certificateFirmaRemotaPin: ''
          });
          _this.props.handlePreviouslyRejectedDocError(error, true);
        }
        if (sessionOpened) {
          _this.firmaRemotaCloseSession();
        }
      });
  }

  signDocRedTrust() {
    const _this = this;
    const { oauthTokenB64, certificateSelected, certificateRedTrustPin, accessMethod } = this.state;
    const { SignerDTO } = this.props.selectedDoc;

    const dataJson = {
      CertGUID: certificateSelected.GUID,
      PIN: certificateRedTrustPin,
      CertType: certificateSelected.CertType,
      AccessMethod: accessMethod
    };

    postPrivSignature(generateRedTrustUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
      .then(res => {
        _this.handleSignatureSuccess(res.status, REDTRUST);
      })
      .catch(function (error) {
        _this.handleSignatureError(error, REDTRUST_CERTIFICATE_PIN_KEY);
      });
  }

  handleSignatureSuccess(status, signatureName) {
    const { SignerDTO } = this.props.selectedDoc;

    if (status === 204) {
      Utils.removeItemFromSelectedDocsInSessionByGuid(SignerDTO.SignerGUI);

      var targetState = { docsSigned: this.state.docsSigned + 1 };

      this.props.showSignedDocHandler();

      if (signatureName === NEBULA)
        targetState.certificateNebulaPin = '';

      this.setState(targetState)
    }
  }

  handleSignatureError(error, certificatePinKey) {
    if (error.response.status === 401) {
      this.setState({ sessionExpired: true });
      this.startTimer();
    } else {
      this.setState({
        loadingSignatureProcess: false,
        errorType: error.response.status,
        errorData: error.response.data,
        showError: true,
        [certificatePinKey]: '',
        certificateUanatacaOtp: '',
        certificateValidatedIdOtp: ''
      });

      this.props.handlePreviouslyRejectedDocError(error, true);
    }
  }

  signDocUanataca() {
    const _this = this;
    const { oauthTokenB64, certificateSelected, certificateUanatacaPin, certificateUanatacaOtp, isUanatacaQualified, accessMethod } = this.state;
    const { SignerDTO } = this.props.selectedDoc;

    var dataJson = {
      CertGUID: certificateSelected.GUID,
      PIN: certificateUanatacaPin,
      CertType: certificateSelected.CertType,
      AccessMethod: accessMethod
    };

    if (isUanatacaQualified) {
      const dataJsonQF = Object.assign(dataJson, { OTP: certificateUanatacaOtp });
      dataJson = dataJsonQF;
    }
    
    postPrivSignature(generateUanatacaUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
        .then(res => {
          _this.handleSignatureSuccess(res.status, UANATACA);
        })
        .catch(function (error) {
          _this.handleSignatureError(error, UANATACA_CERTIFICATE_PIN_KEY);                
        });
  }

  signDocValidatedId() {
    const _this = this;
    const { oauthTokenB64, certificateSelected, certificateValidatedIdPin, certificateValidatedIdOtp, isValidatedIdQualified, accessMethod } = this.state;
    const { SignerDTO } = this.props.selectedDoc;

    var dataJson = {
      CertGUID: certificateSelected.GUID,
      PIN: certificateValidatedIdPin,
      CertType: certificateSelected.CertType,
      AccessMethod: accessMethod
    };

    if (isValidatedIdQualified) {
      const dataJsonQF = Object.assign(dataJson, { OTP: certificateValidatedIdOtp });
      dataJson = dataJsonQF;
    }

    postPrivSignature(generateValidatedIdUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
        .then(res => {
          _this.handleSignatureSuccess(res.status, VALIDATEDID);
        })
        .catch(function (error) {
          _this.handleSignatureError(error, VALIDATEDID_CERTIFICATE_PIN_KEY);
        });
  }

  signDocSwisscom() {
    const _this = this;
    const { oauthTokenB64, accessMethod} = this.state;
    const { SignerDTO } = this.props.selectedDoc;
    const dataJson = {
      AccessMethod: accessMethod
    }
    postPrivSignature(generateSwisscomUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
      .then(res => {
        var swisscomReturnObj = res.data;
        var isUrlDefined = swisscomReturnObj.RedirectURL ? true : false;

        if (isUrlDefined) {
          Utils.openTab(swisscomReturnObj.RedirectURL);
        }

        this.setState({ swisscomRedirectURL: swisscomReturnObj.RedirectURL, swisscomRequestID: swisscomReturnObj.RequestID, loadingSwisscomRequest: false });
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
          _this.setState({ sessionExpired: true });
          _this.startTimer();
        } else {
          _this.setState({
            errorType: error.response.status,
            errorData: error.response.data,
            showError: true,
            showSwisscomConfirmationModal: false,
            showSwisscomModal: false,
            loadingSwisscomCheck: false,
            loadingSwisscomRequest: false
          });
          _this.props.handlePreviouslyRejectedDocError(error, true);
        }
      });
  }

  completeSwisscomSignature() {
    const _this = this;
    const { t } = this.props;
    const { oauthTokenB64, swisscomRequestID, accessMethod } = this.state;
    const { SignerDTO, DocGUI } = this.props.selectedDoc;
    const dataJson = {
      AccessMethod: accessMethod
    }

    postPrivSignature(generateSwisscomUri(SignerDTO.SignerGUI, swisscomRequestID), dataJson, oauthTokenB64)
      .then(res => {
        Utils.removeItemFromSelectedDocsInSessionByGuid(DocGUI);
        _this.setState({ loadingSwisscomCheck: false, showSwisscomConfirmationModal: false });
        _this.props.showSignedDocHandler();
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            _this.setState({ sessionExpired: true });
            _this.startTimer();
          } else if (error.response.status === 409) {
            _this.setState({
              loadingSwisscomCheck: false,
              swisscomError: t('documents.swisscom-error')
            });
          } else {
            _this.setState({
              errorType: error.response.status,
              errorData: error.response.data,
              showError: true,
              showSwisscomConfirmationModal: false,
              showSwisscomModal: false,
              loadingSwisscomCheck: false
            });
          }
        }
        else {
          _this.setState({
            errorType: 'Undefined',
            errorData: 'Network error',
            showError: true,
            showSwisscomConfirmationModal: false,
            showSwisscomModal: false,
            loadingSwisscomCheck: false
          });
        }
      });
  }

  signDocAutofirma() {
    this.loadAppAFirma().then(() => {
      this.doSelectCert();
    });
  }

  loadAppAFirma() {
    return new Promise((resolve, reject) => {
      window.AutoScript.cargarAppAfirma();
      window.AutoScript.setStickySignatory(true);
      setTimeout(() => { resolve(); }, 1500);
    });
  }

  getAuthenticators() {
    const _this = this;
    const { oauthTokenB64 } = this.state;
    var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
    _this.setState({ loadingNebula: true });

    const dataJson = {
      IsSign: true
    };

    postManagementSignature(generateNebulaAuthUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
      .then(res => {
          this.authenticators = res.data.Authenticators;

          if (this.authenticators.length === 1) {
            _this.setState({
              nebulaStep1: false,
              nebulaStep2: true,
              NBauthenticator: this.authenticators[0]
            });
            _this.sendAuthenticator();
          } else {
            _this.setState({
              nebulaStep1: true,
              NBAuthenticator: this.state.NBAuthenticator
            });
          }
      })
      .catch(function (error) {
        this.setState({ loadingNebula: false });
        _this.handleGenericError(error);
      });
  }

  handleAuthenticatorChange(e) {
    this.setState({ NBauthenticator: e.target.value })
  }

  sendAuthenticator() {
    const _this = this;
    const { oauthTokenB64, NBauthenticator } = this.state;
    var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
    _this.setState({ loadingNebula: true });

    const dataJson = {
      Authenticator: NBauthenticator,
    };

    postManagementSignature(generateNebulaAuthMiddleUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
      .then(res => {
          _this.setState({
            nebulaStep1: false,
            nebulaStep2: true,
            loadingNebula: false
          });
      })
      .catch(function (error) {
        this.setState({ loadingNebula: false });
          _this.handleGenericError(error);
      });
  }

  sendChallenge() {
    const _this = this;
    const { oauthTokenB64, NBChallenge, nebulaPinRequired } = this.state;
    var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
    _this.setState({ loadingNebula: true, nebulaStep2: false });

    const dataJson = {
      Challenge: NBChallenge,
      };

    postManagementSignature(generateNebulaAuthEndUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
      .then(res => {
        if (!nebulaPinRequired) {
          _this.signDocNebula();
        }
        _this.setState({
          nebulaStep1: false,
          nebulaStep2: false,
          nebulaStep3: true,
          loadingNebula: false,
          NBChallenge: ''
        });
      })
      .catch(function (error) {
        if (error.response.data.includes("integrationUnauthorized")) {
          _this.setState({
            showChallengeError: true,
            loadingNebula: false,
            NBChallenge: ''
          });
        } else {
          /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
          _this.setState({ sessionExpired: true, loadingNebula: false, NBChallenge: '' });
          _this.startTimer();
        }
      });
  }

  signDocSelection(type) {
    const { sessionOpened, docsSigned } = this.state;

    if (docsSigned !== 1) {
      if (type === SOFTWARE) {
        this.signDocSoftware();
      }
      if (type === KEYVAULT) {
        this.signDocKeyVault();
      }
      if (type === REDTRUST) {
        this.signDocRedTrust();
      }
      if (type === FIRMAREMOTA) {
        this.signDocFirmaRemota();
      }
      if (type === UANATACA) {
        this.signDocUanataca();
      }
      if (type === SWISSCOM) {
        this.signDocSwisscom();
      }
      if (type === NEBULA) {
        this.signDocNebula();
      }
      if (type === VALIDATEDID) {
        this.signDocValidatedId();
      }

      return;
    }

    if (sessionOpened) {
      this.firmaRemotaCloseSession();
    }

    this.setState({
      loadingSignatureProcess: false,
      certificateSoftwarePin: '',
      certificateKeyVaultPin: '',
      certificateFirmaRemotaPin: '',
      certificateRedTrustPin: '',
      certificateUanatacaPin: '',
      certificateUanatacaOtp: '',
      certificateNebulaPin: '',
      certificateValidatedIdPin: '',
      certificateValidatedIdOtp: '',
      signedDoc: true
    });
  }

  signDocNebula() {
    const _this = this;
    const { oauthTokenB64, certificateSelected, certificateNebulaPin, accessMethod } = this.state;
    const { SignerDTO } = this.props.selectedDoc;
    this.setState({
      loadingSignatureProcess: true,
      showNebulacaModal: false,
      showNoPinConfirmationModal: false,
      nebulaStep3: false,
    });

    const dataJson = {
      CertGUID: certificateSelected.GUID,
      PIN: certificateNebulaPin,
      CertType: certificateSelected.CertType,
      AccessMethod: accessMethod
  };

  postPrivSignature(generateNebulaUri(SignerDTO.SignerGUI), dataJson, oauthTokenB64)
      .then(res => {
          _this.handleSignatureSuccess(res.status, NEBULA);
      })
      .catch(function (error) {
          _this.handleSignatureError(error, NEBULA_CERTIFICATE_PIN_KEY);
      })
  }

  doSelectCert() {
    try {
      window.AutoScript.selectCertificate(
        "",
        this.showCertCallback.bind(this),
        this.showErrorCallback.bind(this)
      );

    } catch (e) {
      console.log("Type: " + window.AutoScript.getErrorType() + "\nMessage: " + window.AutoScript.getErrorMessage());
      this.setState({ showAutofirmaModal: false });
    }
  }

  // FUNCION DE FIRMA
  doSign(data) {
    const _this = this;
    const { oauthTokenB64 } = this.state;
    const { SignerDTO } = this.props.selectedDoc;
    const { t } = this.props;

    this.setState({ autofirmaPercentage: 40 });

    axios({
      method: 'post',
      url: getVidCloudPrivUrl() + '/localsignature/' + SignerDTO.SignerGUI + '/certificate',
      data: {
        CertType: data.certType,
        CertsData: [data.certsData],
        HashAlgorithm: data.hashAlgorithm
      },
      async: true,
      dataType: "json",
      contentType: 'application/json',
      crossDomain: true,
      headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
    })
      .then(res => {
        if (res.status === 204) {
          _this.doAutofirmaPreSignature()
            .then(response => {
              _this.setState({ autofirmaPercentage: 60 });
              try {
                /* SE FILTRA POR EL CN SELECCIONADO PREVIAMENTE Y
                 * SE ACTIVA EL HEADLESS PARA EVITAR QUE VUELVA A SOLICITAR EL CERTIFICADO
                 * */
                window.AutoScript.sign(
                  response.PreSignatureContentDNIe,
                  "SHA512withRSA",
                  "NONE",
                  null,
                  this.showSignResultCallback.bind(this),
                  this.showErrorCallback.bind(this));
              } catch (e) {
                _this.setState({ loadingSignatureProcessAutofirma: false });
                try {
                  console.log("Type: " + window.AutoScript.getErrorType() + "\nMessage: " + window.AutoScript.getErrorMessage());
                } catch (ex) {
                  console.log("Error: " + e);
                }
              }
            })
            .catch(function (error) {
              _this.setState({
                loadingSignatureProcessAutofirma: false,
                showAutofirmaModal: false,
                errorType: t('documents.autofirma-title'),
                errorData: error.message,
                showError: true
              });
            });
        }
      })
      .catch(function (error) {
        _this.setState({
          loadingSignatureProcessAutofirma: false,
          showAutofirmaModal: false,
          errorType: t('documents.autofirma-title'),
          errorData: error.message,
          showError: true
        });
      });
  }

  doAutofirmaPreSignature() {
    const _this = this;
    const { oauthTokenB64 } = this.state;
    const { SignerDTO } = this.props.selectedDoc;

    var config = {
      headers: {
        'Authorization': 'Bearer ' + oauthTokenB64
      }
    };
    return new Promise(function (resolve, reject) {
      axios.get(getVidCloudPrivUrl() + '/presignature?SignatureId=' + SignerDTO.SignerGUI, config)
        .then(res => {
          resolve(res.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            _this.setState({ sessionExpired: true });
            _this.startTimer();
          }
          reject(error);
        });
    });
  }

  // FUNCION CALLBACK DE AUTOFIRMA PARA ENVIAR EL RESULTADO DE LA FIRMA
  showSignResultCallback(signatureB64, certificateB64) {
    const _this = this;
    const { oauthTokenB64, clientIp, ipData } = this.state;
    const { SignerDTO } = this.props.selectedDoc;
    const { t } = this.props;
    const evidenceLocation = (ipData.latitude + ipData.longitude).toString;
    const browserStringified = JSON.stringify(browser);

    this.setState({ autofirmaPercentage: 80 });

    axios({
      method: 'post',
      url: getVidCloudPrivUrl() + '/localsignature/' + SignerDTO.SignerGUI,
      data: {
        Signature: signatureB64,
        HashAlgorithm: "SHA512",
        IpAddress: clientIp,
        Location: evidenceLocation,
        From: browserStringified
      },
      async: true,
      dataType: "json",
      contentType: 'application/json',
      crossDomain: true,
      headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
    })
      .then(res => {
        this.setState({ autofirmaPercentage: 100 });
        this.closeAutofirmaProcess();
      })
      .catch(function (error) {
        _this.setState({
          loadingSignatureProcessAutofirma: false,
          showAutofirmaModal: false,
          errorType: t('documents.autofirma-title'),
          errorData: error.message,
          showError: true
        });
      });
  }

  closeAutofirmaProcess() {
    const _this = this;
    setTimeout(() => {
      _this.setState({
        loadingSignatureProcessAutofirma: false,
        showAutofirmaModal: false
      }, () => this.refreshView());
    }, 2000);
  }

  refreshView() {
    this.props.showSignedDocHandler();
  }

  // FUNCION CALLBACK DE AUTORFIRMA PARA RECOGER EL CERTIFICADO A USAR
  showCertCallback(certificateB64) {
    const { SignerDTO } = this.props.selectedDoc;

    this.setState({
      loadingSignatureProcessAutofirma: true,
      autofirmaPercentage: 20
    });

    var pki = window.forge.pki;
    var certPEM = "-----BEGIN CERTIFICATE-----\n" + certificateB64 + "\n-----END CERTIFICATE-----";
    var cert = pki.certificateFromPem(certPEM);
    var CN = cert.subject.getField('CN').value;

    var data = {
      signerGUI: SignerDTO.SignerGUI,
      certType: SignerDTO.TypeOfID,
      certsData: certificateB64,
      hashAlgorithm: "SHA512",
      cn: CN
    };
    this.doSign(data);
  }

  showErrorCallback(errorType, errorMessage) {
    const { t } = this.props;
    /*if (errorType == "es.gob.afirma.standalone.ApplicationNotFoundException") {
     console.log("Autofirma application is not installed.");
     }
     else {
     console.log("Type: " + errorType + "\nMessage: " + errorMessage);
     }*/
    this.setState({
      showAutofirmaModal: false,
      errorType: t('documents.autofirma-title'),
      errorData: <p>{t('documents.autofirma-error')} <a href='https://firmaelectronica.gob.es/Home/Descargas.html' rel="noopener noreferrer" target='_blank'>https://firmaelectronica.gob.es/Home/Descargas.html</a></p>,
      showError: true
    });
  }

  getClientData() {
    const _this = this;
    (async () => {
      const clientIp = await publicIp.v4();
      const ipData = await ipLocation(clientIp);
      _this.setState({
        clientIp: clientIp,
        ipData: ipData
      });
    })();
  }

  getImagesHeightsAverage() {
    const images = document.getElementsByClassName("pdf-images");

    const imagesArray = Array.from(images);

    let totalHeight = 0;

    imagesArray.forEach((image) => {
      totalHeight += image.offsetHeight;
    })

    return totalHeight / imagesArray.length;
  }

  actualPage() {
    const { renderedDoc, renderDocument } = this.props;

    const renderedPages = renderedDoc.length;

    var container_height = document.getElementsByClassName("pdf-container")[0].offsetHeight;
  
    const image_height = this.getImagesHeightsAverage();

    var scroll_height = image_height * renderedPages;

    /**Scroll position*/
    var scroll_position = document.getElementsByClassName("pdf-container")[0].scrollTop;
    var scroll_max_value = scroll_height - container_height;
    var scroll_percentage = Math.abs((scroll_position / scroll_max_value) * 100);

    var page_ratio = 100 / renderedPages; /**6,25  */
    var actual_page = scroll_percentage / page_ratio; /**Por cada 6,25 saldrá 1 */
    var max_page_ratio = 100 / page_ratio; /**Número de páginas en total */

    /**Sumamos 1 de base para no mostrar el valor 0 */
    var actual_page_absolute = 1 + Math.floor(actual_page)

    /**Comprobamos que no llegue al máximo para no mostrar el máximo de páginas +1 y le adjudicamos el valor máximo */
    if (actual_page_absolute >= max_page_ratio) {
      actual_page_absolute = renderedPages;
    }

    if (actual_page_absolute >= renderedPages - 1) renderDocument();

    this.setState({ actualPage: actual_page_absolute })
  }

  actualAttachedPage() {
    /**REVISAR SI SE PUEDE USAR EL MISMO MÉTODO DE ACTUAL PAGE */
    const { docsAttachedRendered } = this.props;

    var container_height = document.getElementsByClassName("pdf-container-2")[0].offsetHeight;
    var image_height = document.getElementsByClassName("attached-pdf-images")[0].offsetHeight;
    var scroll_height = image_height * docsAttachedRendered.length;

    /**Scroll position*/
    var scroll_position = document.getElementsByClassName("pdf-container-2")[0].scrollTop;
    var scroll_max_value = scroll_height - container_height;
    var scroll_percentage = Math.abs((scroll_position / scroll_max_value) * 100);

    var page_ratio = 100 / docsAttachedRendered.length; /**6,25  */
    var actual_page = scroll_percentage / page_ratio; /**Por cada 6,25 saldrá 1 */
    var max_page_ratio = 100 / page_ratio; /**Número de páginas en total */

    /**Sumamos 1 de base para no mostrar el valor 0 */
    var actual_page_absolute = 1 + Math.floor(actual_page)

    /**Comprobamos que no llegue al máximo para no mostrar el máximo de páginas +1 y le adjudicamos el valor máximo */
    if (actual_page_absolute >= max_page_ratio) {
      actual_page_absolute = docsAttachedRendered.length;
    }
    this.setState({ actualAttachedPage: actual_page_absolute })
  }

  changeAttachedTab(k) {
    this.setState({
      tabKey: k,
      actualAttachedPage: 1
    });
    document.getElementsByClassName("pdf-container-2")[0].scrollTop = 0;
  }

  toggleSignatureReason() {
    this.setState({ showSignatureReason: !this.state.showSignatureReason })
  }

  handleSign(validateDisclaimer = true) {
    const { selectedDoc } = this.props;
    if (selectedDoc.SignerDTO.DisclaimerInfo.Display && validateDisclaimer) {
      this.getDisclaimerMessage();
      return;
    }

    this.setState({ showAdditionalSignaturesDisclaimer: false });

    if (this.state.vuserCertsFiltered.length === 1) {
      this.handleCertSelection(this.state.vuserCertsFiltered[0]);
      return
    }

    this.setState({ showSignModal: true })
  }

  getDisclaimerMessage() {
    const type = 'SIGNATURE_MULTIPOSITION_SIMPLE';
    const _this = this;
    const { oauthTokenB64, language } = this.state;
    const { SignerDTO } = this.props.selectedDoc;
    var config = {
      headers: {
        'Authorization': 'Bearer ' + oauthTokenB64
      }
    };

    const iso2Language = language.slice(0, 2);

    return new Promise(function (resolve, reject) {
      axios.get(getVidCloudPrivUrl() + '/pendingsignatures/' + SignerDTO.SignerGUI + '/disclaimertext/' + type + '/' + iso2Language, config)
        .then(res => {
          resolve(res.data);
          const disclaimerData = res.data;
          _this.setState(
            {
              showAdditionalSignaturesDisclaimer: true,
              textAdditionalSignaturesDisclaimer: disclaimerData.Text,
              titleAdditionalSignaturesDisclaimer: disclaimerData.Title
            });
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            _this.setState({ sessionExpired: true });
            _this.startTimer();
          }
          reject(error);
        });
    });
  }

  onHeaderInfoClick() {
    this.setState({
      showHeaderInfo: !this.state.showHeaderInfo
    });
  }

  render() {
    const { t, renderedDoc, selectedDoc, pendingSignatures, documentSigners, documentAttatchmentsList, attDocArray, docsAttachedRendered, totalPages, checkedDocs, updateSelectedDocs } = this.props;
    const {
      showSignModal, showRejectModal, sessionExpired, language, time, certificatesList,
      showKeyVaultModal, showFirmaRemotaModal, showRedTrustModal, showSoftwareModal, showUanatacaModalPin, showUanatacaModalOtp, showAutofirmaModal, loadingSignatureProcess, loadingRejectingProcess,
      certificateSelected, showError, errorType, errorData, actualPage, showNoPinConfirmationModal, tabKey, actualAttachedPage, loadingSignatureProcessAutofirma, autofirmaPercentage, showSignatureReason, vuserCertsFiltered, showAdditionalSignaturesDisclaimer, rejectReason, titleAdditionalSignaturesDisclaimer,
      textAdditionalSignaturesDisclaimer, showValidatedIdModalPin, showValidatedIdModalOtp, showHeaderInfo
    } = this.state;
    const isDocSelected = checkedDocs?.includes(selectedDoc.SignerDTO.SignerGUI);

    let images = renderedDoc.map((renderedImage, index) => {
      return <img
        key={index}
        alt="renderedImage"
        src={`data:image/jpeg;base64,${renderedImage}`}
        className="pdf-images"
        id={`pdf-page-${index + 1}`}
      />
    });
    let attachedImages = docsAttachedRendered.map((renderedImage, index) => {
      return <img
        key={index}
        alt="attachedRenderedImage"
        src={`data:image/jpeg;base64,${renderedImage}`}
        className="attached-pdf-images"
      />
    });

    const onCheck = () => {
      updateSelectedDocs(selectedDoc, !isDocSelected, PREVIEW);
    };

    var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';

    const columns = [{
      dataField: 'SignerName',
      text: t('documents.document-signers-table-name'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'UploadTime',
      text: t('documents.document-signers-table-delivery-date'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'OperationTime',
      text: t('documents.document-signers-table-signature-date'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'SignatureStatus',
      text: t('documents.document-signers-table-status'),
      headerAlign: 'center',
      formatter: (cellContent, row, rowIndex) => {
        if (cellContent !== null) {
          if (cellContent === 'Signed') {
            return <div className="signedCellbox">{cellContent}</div>
          }
          if (cellContent === 'Unsigned') {
            return <div className="unsignedCellbox">{cellContent}</div>
          }
          if (cellContent === 'Rejected') {
            return <div className="rejectedCellbox">{cellContent}</div>
          }
        }
        else {
          return <div className="undefinedCellbox">Undefined</div>
        }
      }
    }];

    const approvers = documentSigners.reduce((approversData, signer) => {
      if (Array.isArray(signer.Approvers) && signer.Approvers?.length > 0) return [
        ...approversData,
        ...signer.Approvers
      ];

      return approversData;
    }, []);

    const showApproversTab = approvers.length > 0;
    
    const approversColumns = showApproversTab ? [{
      dataField: 'Email',
      text: t('profile.user-email'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'LegalId',
      text: t('profile.user-legalId'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'Name',
      text: t('documents.document-signers-table-name'),
      align: 'left',
      headerAlign: 'left'
    }] : [];

    const isInvalidRejectionReason = rejectReason.length < REJECTION_REASON_MIN_CHARACTERS;
    const docIndex = pendingSignatures.findIndex((doc) => doc.DocGUI === selectedDoc.DocGUI);
    const isLastIndex = docIndex === pendingSignatures.length - 1;


    return (
      <div>
        {showError &&
          <Toast
            onClose={() => this.setState({ showError: false })}
            show={showError}
            animation={true}

            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              margin: '0 auto',
              backgroundColor: 'rgba(255,255,255,1)',
              border: 'none',
              zIndex: 10
            }}
          >
            <Toast.Header
              closeButton={true}
            >
              <strong className="mr-auto">{t('general.error')} {errorType}</strong>
            </Toast.Header>
            <Toast.Body>{errorData}</Toast.Body>
          </Toast>
        }
        {sessionExpired &&
          <div className="toast-container">
            <Toast
              show={sessionExpired}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                margin: '0 auto',
                backgroundColor: 'rgba(255,255,255,1)',
                border: 'none',
                zIndex: 10
              }}
            >
              <Toast.Header
                closeButton={false}
              >
                <strong className="mr-auto">{t('general.notification')}</strong>
                <small>{timeText}</small>
              </Toast.Header>
              <Toast.Body>
                <p>{t('general.session-expired')}</p>
                <Button
                  type="button"
                  className="btn btn-primary"
                  id="sign-all-docs"
                  onClick={() => this.doLogOut()}
                >
                  {t('general.accept')}
                </Button>
              </Toast.Body>
            </Toast>
          </div>
        }
        {(renderedDoc.length === 0) &&
          <LoadingCircle centerVertically={true} />
        }
        {(renderedDoc.length > 0) &&
          <div>
            <div className="back-container" onClick={this.props.viewDocumentHandler}>
              <span><img src={iconBackButton} alt="back button" className="back-button" /> <span className="back-button-text">{t('documents.document-back-button')}</span></span>
            </div>
            { !isLastIndex &&
              <div className="next-container" onClick={this.props.seeNextDocument}>
                <span className="back-button-text"> {t('documents.document-next-button')} </span>&nbsp;&nbsp;<span><img src={iconNextButton} alt="back button" className="back-button" /> </span>
              </div>
            }
            <Tabs defaultActiveKey="document" id="uncontrolled-tab-example">
              <Tab eventKey="document" title={<span>{t('documents.document-tab-sign')}</span>}>
            
                <div className={`document-preview-header ${showHeaderInfo ? '' : 'invisible'}`}>
                    <div className="data-group">
                      <img src={squareEyeBox} alt="Eye" className="" />
                      <label className="document-preview-title"><b>{selectedDoc.SignerDTO.SignerName}</b> {t('documents.dp-text1')}</label>
                    </div>
                    <div className="data-group">
                      <img src={squareDocBox} alt="Doc" className="" />
                      <label className="document-preview-name">{selectedDoc.FileName}</label>
                    </div>
                    <label className="check-container">{t('documents.select')}
                      <input onChange={onCheck} type="checkbox" checked={isDocSelected}/>
                      <div className='checkmark'></div>
                    </label>
                    <span className="document-preview-count">{actualPage}/{totalPages}</span>
                    <div className={`preview-toggler ${showHeaderInfo ? '' : 'plus-preview-toggler'}`} onClick={this.onHeaderInfoClick}>
                      {showHeaderInfo ? <img src={iconArrowUp} alt="Hide"/> : <img src={iconArrowDown} alt="Show"/>}
                    </div>
                  </div>
                <div className="document-preview-container">
                  <Modal show={showSignModal} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t('documents.cert-type-selection')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ListGroup defaultActiveKey="#link1">
                        {certificatesList}
                      </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="reject"
                        onClick={() => this.handleClose()}
                        style={{ margin: 'auto' }}
                      >
                        {t('general.cancel')}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showRejectModal} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                      {certificateSelected && <Modal.Title>{t('documents.reject-document')}</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                      <p>{t('documents.reject-document-question')}</p>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>{t('documents.reject-reason')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          as="textarea"
                          maxLength="450"
                          placeholder={t('documents.reject-reason-placeholder')}
                          value={rejectReason || ''}
                          onChange={e => this.setState({ rejectReason: e.target.value })}
                          className="reject-reason"
                        />
                      </InputGroup>
                      {
                        isInvalidRejectionReason && <span className="rejection-error">
                          {t("documents.rejection-reason-validation")}
                        </span>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="centered-buttons">
                        <Button
                          className="reject"
                          onClick={() => this.handleClose()}
                        >
                          {t('general.cancel')}
                        </Button>
                        <Button
                          disabled={isInvalidRejectionReason}
                          variant="primary"
                          onClick={() => this.rejectDoc()}
                          style={{ marginLeft: 20 }}
                        >
                          {t('general.confirm')}
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  <CustomModal
                    text={textAdditionalSignaturesDisclaimer}
                    title={titleAdditionalSignaturesDisclaimer}
                    show={showAdditionalSignaturesDisclaimer}
                    handleHide={() => this.setState({ showAdditionalSignaturesDisclaimer: false })}
                    handleConfirm={() => this.handleSign(false)}
                  />

                  <KeyVaultSignature
                    show={showKeyVaultModal}
                    onClose={this.handleClose}
                    onSuccess={this.handleModalSignatureSuccess}
                    certificateSelected={certificateSelected} />

                  <FirmaRemota
                    show={showFirmaRemotaModal}
                    onClose={this.handleClose}
                    onSuccess={this.handleModalSignatureSuccess}
                    certificateSelected={certificateSelected} />

                  <RedTrustSignature
                    show={showRedTrustModal}
                    onClose={this.handleClose}
                    onSuccess={this.handleModalSignatureSuccess} />

                  <SoftwareSignature
                    show={showSoftwareModal}
                    onClose={this.handleClose}
                    onSuccess={this.handleModalSignatureSuccess} />

                  <UanatacaSignature 
                    showModalPin={showUanatacaModalPin}
                    showModalOtp={showUanatacaModalOtp}
                    onClose={this.handleClose}
                    onSuccessOtp={this.handleModalSignatureOtpSuccess}
                    onSuccess={this.handleModalSignatureSuccess} />

                  <ValidatedIdSignature
                    showModalPin={showValidatedIdModalPin}
                    showModalOtp={showValidatedIdModalOtp}
                    onClose={this.handleClose}
                    onSuccessOtp={this.handleModalSignatureValidatedIdSuccess}
                    onSuccess={this.handleModalSignatureSuccess} />

                  <SwisscomSignature
                            parentState={this.state} 
                            onClose={this.handleClose} 
                            onSuccessSignDocSelection={() => this.setState({
                                showSwisscomModal: false,
                                showSwisscomConfirmationModal: true,
                                loadingSwisscomRequest: true
                            }, () => this.signDocSelection(SWISSCOM))}
                            onSuccess={() => this.setState({ 
                                loadingSwisscomCheck: true, 
                                swisscomError: '' }, 
                                () => this.completeSwisscomSignature())} />

                  
                  <Modal show={showAutofirmaModal} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t('documents.autofirma-title')}</Modal.Title>
                    </Modal.Header>
                    {!loadingSignatureProcessAutofirma &&
                      <Modal.Body>
                        <p>{t('documents.autofirma-text')}</p>
                        <LoadingCircle />
                      </Modal.Body>
                    }
                    {loadingSignatureProcessAutofirma &&
                      <Modal.Body>
                        <p style={{ textAlign: 'center' }}>{t('general.sign-process')}</p>
                        <ProgressBar variant="success" now={autofirmaPercentage} label={`${autofirmaPercentage}%`} />
                      </Modal.Body>
                    }
                  </Modal>
                  <Modal show={showNoPinConfirmationModal} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t('documents.no-pin-title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {t('documents.no-pin-text')}
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="centered-buttons">
                        <Button
                          className="reject"
                          onClick={() => this.handleClose()}
                        >
                          {t('general.cancel')}
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => this.handleNoPinDocSignature()}
                          style={{ marginLeft: 20 }}
                        >
                          {t('general.confirm')}
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  
                  <NebulaSignature
                    onChangeFormControl={(e) => this.handleAuthenticatorChange(e)} 
                    onSendAuthenticator={this.sendAuthenticator}
                    parentState={this.state}
                    authenticators={this.authenticators}
                    onClose={this.handleClose}
                    onChallengeChange={e => this.setState({ NBChallenge: e.target.value })}
                    onSendChallenge={this.sendChallenge}
                    onFocusInput={(input) => { this.focusInput(input); }}
                    onCertificatePinChange={e => this.setState({ certificateNebulaPin: e.target.value })}
                    onSignDoc={() => this.setState({ loadingSignatureProcess: false, showNebulaModal: false }, () => this.signDocSelection(NEBULA))}
                  />

                  <SigningProcessModal
                    showModal={loadingSignatureProcess}
                    showProcess={true}
                    signedDocs={0}
                    selectedDocs={1} />

                  <RejectingProcessModal
                    show={loadingRejectingProcess} />

                  

                  <div className="pdf-container">
                    {images}
                  </div>
                </div>

                {selectedDoc.SignerDTO.SignatureReason !== null &&
                  <div className="bottom-content-signature-reason" style={{ height: showSignatureReason ? '21vh' : '11vh' }}>
                    <div className="signature-reason-container" style={{
                      height: showSignatureReason ? '60%' : '25%',
                      backgroundColor: showSignatureReason ? 'initial' : '#8E8E8E'
                    }}>
                      <span className="toggle-signature-reason"
                        onClick={() => this.toggleSignatureReason()}>
                        {showSignatureReason &&
                          <img className="arrow-down-icon" src={iconArrowDown} alt="arrow down" />}
                        {!showSignatureReason &&
                          <img className="arrow-down-icon" src={iconArrowUp} alt="arrow up" />}
                      </span>
                      {showSignatureReason &&
                        <div className="signature-reason-title-container">
                          <div className="sr-title-icon-container">
                            <img className="question-box-icon" src={iconQuestionBox}
                              alt="question box" />
                            <p className="signature-reason-title">{t('documents.signature-reason-title')}</p>
                          </div>
                        </div>
                      }
                      {showSignatureReason &&
                        <div className="signature-reason-text-container">
                          <p className="signature-reason-text">
                            {selectedDoc.SignerDTO.SignatureReason !== null ? selectedDoc.SignerDTO.SignatureReason : t('documents.signature-reason-empty')}
                          </p>
                        </div>
                      }
                    </div>
                    {vuserCertsFiltered &&
                      <div className="signature-reason-buttons-container"
                        style={{ height: showSignatureReason ? '40%' : '75%' }}>
                        <Button
                          type="button"
                          id="rejectModalbutton"
                          className="action-button reject"
                          onClick={() => this.setState({ showRejectModal: true })}
                        >
                          {t('general.reject')}
                        </Button>
                        <Button
                          type="button"
                          id="signModalButton"
                          className="btn btn-primary action-button"
                          onClick={this.handleSign}
                        >
                          {t('general.sign')}
                        </Button>
                      </div>
                    }
                  </div>
                }

                {selectedDoc.SignerDTO.SignatureReason === null &&
                  <div className="buttons-container">
                    <Button
                      type="button"
                      id="rejectModalbutton"
                      className="action-button reject"
                      onClick={() => this.setState({ showRejectModal: true })}
                    >
                      {t('general.reject')}
                    </Button>
                    <Button
                      type="button"
                      id="signModalButton"
                      className="btn btn-primary action-button"
                      onClick={this.handleSign}
                    >
                      {t('general.sign')}
                    </Button>
                  </div>
                }
              </Tab>

              <Tab eventKey="signers" title={<span>{t('documents.document-tab-signers')}</span>}>
                <div className="signers-table-container">
                  <ToolkitProvider
                    keyField="GUID"
                    data={documentSigners}
                    columns={columns}
                    bootstrap4
                  >
                    {
                      props => (
                        <div>
                          <BootstrapTable
                            hover
                            striped
                            bordered={false}
                            {...props.baseProps}
                          />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </Tab>
              {
                showApproversTab && <Tab eventKey="approvers" title={<span>{t('approvals.approvals')}</span>}>
                  <div className="signers-table-container">
                    <ToolkitProvider
                      keyField="GUID"
                      data={approvers}
                      columns={approversColumns}
                      bootstrap4
                    >
                      {
                        props => (
                          <div>
                            <BootstrapTable
                              hover
                              striped
                              bordered={false}
                              {...props.baseProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                </Tab>
              }
              <Tab eventKey="attachments" title={<span><img src={iconAttachedDocs} alt="" className="tab-icons" /> {t('documents.document-tab-attachments')}</span>} tabClassName={documentAttatchmentsList.length === 0 ? 'tab-hide' : ''}>
                <Tab.Container
                  defaultActiveKey={tabKey}
                  onSelect={k => this.changeAttachedTab(k)}
                >
                  <Row bsPrefix="attachments-row row">
                    <Col sm={3} bsPrefix="left-side-menu-col col">
                      <Nav variant="pills" className="flex-column">
                        {attDocArray}
                      </Nav>
                    </Col>
                    <Col sm={9} bsPrefix="right-side-menu-col col">
                      <Tab.Content bsPrefix="tab-content tab-content3">
                        <Tab.Pane eventKey={tabKey}>
                          {documentAttatchmentsList.length > 0 &&
                            <div className="attachment-container">
                              <div className="document-preview-container">
                                <div className="document-preview-header-2">
                                  <h1 className="document-preview-title">{t('documents.document-attachment-preview-title')}</h1>
                                  <div className="document-preview-subcontainer">
                                    <h2 className="document-preview-name">{documentAttatchmentsList[tabKey].FileName}</h2>
                                    <span
                                      className="document-preview-count">{actualAttachedPage}/{attachedImages.length}</span>
                                  </div>
                                </div>
                                <div className="pdf-container-2" id="pdf-container-2-id">
                                  {attachedImages}
                                </div>
                              </div>
                            </div>
                          }
                          {documentAttatchmentsList.length === 0 &&
                            <div className="document-preview-container">
                              <p>No hay documentos adjuntos</p>
                            </div>
                          }
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Tab>
            </Tabs>
          </div>
        }
      </div>
    );
  }

  componentWillUnmount() {
    document.getElementsByClassName('pdf-container')[0].removeEventListener("scroll", this.actualPage);
    if (this.props.documentAttatchmentsList.length > 0) {
      document.getElementsByClassName('pdf-container-2')[0].removeEventListener("scroll", this.actualAttachedPage);
    }
  }

  componentDidMount() {
    Utils.appendScript("/assets/js/autoscript.js");
    Utils.appendScript("/assets/js/forge.min.js");
    this.getClientData();
    this.loadViDMobileUserCertificateList();
    document.getElementsByClassName('pdf-container')[0].addEventListener("scroll", this.actualPage);
    if (this.props.documentAttatchmentsList.length > 0) {
      document.getElementsByClassName('pdf-container-2')[0].addEventListener("scroll", this.actualAttachedPage);
    }
  }

}

export default withTranslation()(DocumentPreview);