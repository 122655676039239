import React, { Component } from 'react';
import './Documents.css';
import '../App.css';
import Header from "../Header/Header";
import DocumentPreview from "../DocumentPreview/DocumentPreview";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Jumbotron, ListGroup, Modal, Toast, Nav } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { MdRefresh, MdClose } from "react-icons/md";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import axios from "axios";
import { Redirect } from "react-router-dom";
import ThemeService from '../../services/ThemeService';
import Utils from '../../services/Utils';
import iconSeeAttachedDocs from '../../assets/icons/seeAttachedDoc.svg';
import FilterCertificateMsg from './FilterCertificateMsg/FilterCertificateMsg';
import EmptyDocsPanel from '../EmptyDocsPanel/EmptyDocsPanel';
import SearchBar from './SearchBar/SearchBar';
import SigningProcessModal from '../Modals/SigningProcessModal';
import LoadingCircle from '../LoadingCircle';
import documentRenderService from '../../services/documentRender/documentRenderService';
import * as oauthService from '../../services/oauth/oauthService';
import { getVidCloudMgmtUrl, getVidCloudPrivUrl } from '../../services/environment/environmentService';
import TagsList from './TagsList/TagsList';
import CustomToast from './CustomToast/CustomToast';
import { CustomModal } from '../CustomModal/CustomModal';
import { generateKeyVaultUri, generateRedTrustUri, generateSoftwareUri, generateNebulaUri, generateSwisscomUri, 
         generateUanatacaUri, postPrivSignature, generateKeyVaultSendOTPUri, postManagementSignature, generateNebulaAuthMiddleUri, 
         generateNebulaAuthEndUri, generateAutofirmaCertificateUri, generateFirmaRemotaUri, deleteManagementSignature, 
         generateFirmaRemotaSessionUri, putManagementSignature, generateAutofirmaUri, generateAutofirmaPreSignatureUri, 
         getPrivSignature, putPrivSignature, generateFirmaRemotaOTPUri, generateNebulaAuthUri, generateUanatacaOtpUri, generateValidatedIdOtpUri, generateValidatedIdUri } from '../../services/signatureMethods/customServiceSignature';
import { NEBULA, NEBULA_CERTIFICATE_PIN_KEY, SOFTWARE, SOFTWARE_CERTIFICATE_PIN_KEY, KEYVAULT, 
         KEYVAULT_CERTIFICATE_PIN_KEY, REDTRUST, REDTRUST_CERTIFICATE_PIN_KEY, UANATACA, UANATACA_CERTIFICATE_PIN_KEY, 
         FIRMAREMOTA, FIRMAREMOTA_CERTIFICATE_PIN_KEY, SWISSCOM, OTP, VALIDATEDID_CERTIFICATE_PIN_KEY, VALIDATEDID, DOCUMENTS_LIST, PREVIEW } from '../../services/signatureMethods/constants';
import RedTrustSignature from '../RedTrustSignature/RedTrustSignature.component';
import UanatacaSignature from '../UanatacaSignature/UanatacaSignature.component';
import KeyVaultSignature from '../KeyVaultSignature/KeyVaultSignature.component';
import SoftwareSignature from '../SotwareSignature/SoftwareSignature.component';
import FirmaRemota from '../FirmaRemota/FirmaRemota.component';
import AutofirmaSignature from '../AutofirmaSignature/Autofirma.component';
import SwisscomSignature from '../SwisscomSignature/SwisscomSignature.component';
import NebulaSignature from '../NebulaSignature/NebulaSignature.component';
import { checkShowNoPinConfirmationModal } from '../../services/signatureMethods/helperService';
import ValidatedIdSignature from '../ValidatedIdSignature/ValidatedIdSignature.component';
import attachmentIcon from './../../assets/icons/attachment.png';
import infoIcon from './../../assets/icons/info.png';
import { connect } from 'react-redux';
import DocumentsActionTypes from '../../redux/documents/documents.types';

import warning from '../../assets/icons/warning-jumbotron.png';
import { getPendingDocumentsRequest } from '../../redux/documents/documents.actions';

const ipLocation = require("iplocation");
const publicIp = require('public-ip');
const { detect } = require('detect-browser');
const browser = detect();

class Documents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
            language: localStorage.getItem('i18nextLng'),
            userToken: sessionStorage.getItem('userToken'),
            urlReturn: sessionStorage.getItem('urlReturn'),
            docsSelectedSignerGUIString: sessionStorage.getItem('docsSelectedSignerGUIString'),
            docsSelectedSignerGUI: [],
            viewDocument: false,
            loading: true,
            sessionExpired: false,
            redirect: false,
            time: 0,
            selectedDoc: {},
            renderedDoc: {
                pages: [],
                totalPages: 0,
                nextPage: 1
            },
            rejectedDoc: false,
            signedDoc: false,
            redirecting: false,
            tokenExpired: false,
            docsSelected: [],
            docCertSelected: null,
            disable: false,
            certificatesList: [],
            showSignModal: false,
            showRejectModal: false,
            showKeyVaultModal: false,
            showFirmaRemotaModal: false,
            showRedTrustModal: false,
            showSoftwareModal: false,
            showUanatacaModalPin: false,
            showUanatacaModalOtp: false,
            showValidatedIdModalPin: false,
            showValidatedIdModalOtp: false,
            showNebulaModal: false,
            showSwisscomModal: false,
            showAutofirmaModal: false,
            showSwisscomConfirmationModal: false,
            showNoPinConfirmationModal: false,
            certificateSoftwarePin: '',
            certificateKeyVaultPin: '',
            certificateFirmaRemotaPin: '',
            certificateRedTrustPin: '',
            certificateUanatacaPin: '',
            certificateUanatacaOtp: '',
            certificateValidatedIdPin: '',
            certificateValidatedIdOtp: '',
            certificateNebulaPin: '',
            certificateSelected: {},
            loadingSignatureProcess: false,
            sessionOpened: false,
            showError: false,
            errorData: '',
            errorType: '',
            docsSigned: 0,
            certHidden: true,
            userIsLoggedByAuthCerts: false,
            showJumbotron: true,
            documentInfo: {},
            documentSigners: [],
            documentAttatchmentsList: [],
            docsAttachedRendered: [],
            swisscomRedirectURL: "",
            swisscomRequestID: "",
            loadingSwisscomCheck: false,
            swisscomError: '',
            loadingSwisscomRequest: false,
            autofirmaError: '',
            autofirmaPercentage: 0,
            clientIp: '',
            ipData: '',
            currentPageTable: 1,
            currentSizePerPageTable: 10,
            loadingMorePages: false, 
            previouslyRejectedDoc: false,
            showAdvancedSearch: true,
            nebulaStep1: false,
            nebulaStep2: false,
            nebulaStep3: false,
            showAdditionalSignaturesBatchDisclaimer: false,
            showWarningModal: false,
            selectedCertificate: '',
            loadingNebula: false,
            currentDataAutofirma: {},
            showChallengeError: false,
            nebulaPinRequired: true,
            isUanatacaQualified: false,
            isValidatedIdQualified: false,
            preventBatchModal: false,
            nextDocument: {},
            accessMethod: sessionStorage.getItem("AccessMethod")    
        };
        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)
        this.viewDocumentHandler = this.viewDocumentHandler.bind(this)
        this.showRejectedDocHandler = this.showRejectedDocHandler.bind(this)
        this.showSignedDocHandler = this.showSignedDocHandler.bind(this)
        this.seeDocument = this.seeDocument.bind(this)
        this.textFormatter = this.textFormatter.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onSizePerPageChange = this.onSizePerPageChange.bind(this)
        this.renderDocument = this.renderDocument.bind(this)
        this.shouldHideTagColumn = this.shouldHideTagColumn.bind(this)
        this.handleAutofirmaSignatureError = this.handleAutofirmaSignatureError.bind(this)
        this.handlePreviouslyRejectedDocError = this.handlePreviouslyRejectedDocError.bind(this)
        this.handleAuthenticatorChange = this.handleAuthenticatorChange.bind(this);
        this.sendAuthenticator = this.sendAuthenticator.bind(this);
        this.authenticators = [];
        this.handleBatchSignature = this.handleBatchSignature.bind(this)
        this.handleSignatureSuccess = this.handleSignatureSuccess.bind(this);
        this.handleSignatureError = this.handleSignatureError.bind(this);
        this.handleSessionExpiredError = this.handleSessionExpiredError.bind(this);
        this.handleGenericError = this.handleGenericError.bind(this);
        this.handleModalSignatureSuccess = this.handleModalSignatureSuccess.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSwisscomErrorMessage = this.handleSwisscomErrorMessage.bind(this);
        this.getAuthenticators = this.getAuthenticators.bind(this);
        this.sendChallenge = this.sendChallenge.bind(this);
        this.handleModalSignatureOtpSuccess = this.handleModalSignatureOtpSuccess.bind(this);
        this.seeNextDocument = this.seeNextDocument.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleModalSignatureValidatedIdSuccess = this.handleModalSignatureValidatedIdSuccess.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
    }

    viewDocumentHandler() {
        this.clearVariablesToRenderListProperly();
        this.setPendingSignaturesSavedInSession();
        this.setState({
            viewDocument: false
        });
    }

    handleReturnToDocumentsList() {
        this.props.dispatch({
            type: DocumentsActionTypes.MODIFY_PAGINATION,
            payload: {
              ...this.props.paginationMeta,
              PageNumber: 1,
            },
          });
        this.setPendingSignaturesSavedInSession();

        this.getPendingDocuments();
    }

    showRejectedDocHandler() {
        this.unselectDocuments([this.state.selectedDoc]);
        this.handleReturnToDocumentsList();

        this.setState({
            rejectedDoc: true,
            viewDocument: false
        });

    }

    showSignedDocHandler() {
        this.unselectDocuments([this.state.selectedDoc]);
        this.handleReturnToDocumentsList();

        this.setState({
            signedDoc: true,
            viewDocument: false
        });
    }

    doLogOut() {
        var _this = this;
        const { userToken, urlReturn } = this.state;
        if (typeof userToken !== "undefined" && userToken === "true" && typeof urlReturn !== "undefined" && urlReturn !== "null" && urlReturn !== "") {
            _this.setState({
                redirecting: true
            });
            setTimeout(() => {
                window.location.href = atob(urlReturn);
            }, 3000);
        }
        else {
            this.setState({ redirect: true })
        }
        sessionStorage.clear();
    }

    startTimer() {
        this.timer = setInterval(() => this.setState({
            time: this.state.time + 1
        }), 60000);
    }

    stopTimer() {
        clearInterval(this.timer)
    }

    handleModalSignatureSuccess(signatureName, showModalKey, certificatePinKey, certificatePin) {
        this.setState({ 
            loadingSignatureProcess: true, 
            [showModalKey]: false,
            [certificatePinKey]: certificatePin
        },
            () => this.signDocSelection(signatureName))
    }

    handleModalSignatureOtpSuccess(signatureName, showModalKeyPin, showModalKeyOtp, certificatePinKey, certificatePin, certificateOtp) {
        const { isUanatacaQualified } = this.state;
        if (isUanatacaQualified) {
            this.setState({ [showModalKeyOtp]: false, showUanatacaModalPin: true, certificateUanatacaOtp: certificateOtp });
            return;
        }

        this.handleModalSignatureSuccess(signatureName, showModalKeyPin, certificatePinKey, certificatePin);
    }

    handleModalSignatureValidatedIdSuccess(signatureName, showModalKeyPin, showModalKeyOtp, certificatePinKey, certificatePin, certificateOtp) {
        const { isValidatedIdQualified } = this.state;
        if (isValidatedIdQualified) {
            this.setState({ [showModalKeyOtp]: false, showValidatedIdModalPin: true, certificateValidatedIdOtp: certificateOtp });
            return;
        }

        this.handleModalSignatureSuccess(signatureName, showModalKeyPin, certificatePinKey, certificatePin);
    }

    handleClose() {
        this.setState({
            showSignModal: false,
            showKeyVaultModal: false,
            showFirmaRemotaModal: false,
            showRedTrustModal: false,
            showSoftwareModal: false,
            showUanatacaModalPin: false,
            showUanatacaModalOtp: false,
            showValidatedIdModalPin: false,
            showValidatedIdModalOtp: false,
            showSwisscomModal: false,
            showAutofirmaModal: false,
            showSwisscomConfirmationModal: false,
            showNoPinConfirmationModal: false,
            loadingSignatureProcess: false,
            loadingSwisscomCheck: false,
            loadingSignatureProcessAutofirma: false,
            certificateSoftwarePin: '',
            certificateKeyVaultPin: '',
            certificateFirmaRemotaPin: '',
            certificateRedTrustPin: '',
            certificateUanatacaPin: '',
            certificateUanatacaOtp: '',
            certificateValidatedIdPin: '',
            certificateValidatedIdOtp: '',
            certificateNebulaPin: '',
            loadingSwisscomRequest: false,
            nebulaStep1: false,
            nebulaStep2: false,
            nebulaStep3: false,
            NBauthenticator: '',
            NBChallenge: '',
            showWarningModal: false,
            loadingNebula: false,
            showChallengeError: false,
            textAdditionalSignaturesDisclaimer: '',
            titleAdditionalSignaturesDisclaimer: '',
        })
    }

    isSelectAllHidden = () => {
        const { pendingDocuments } = this.props;
        let hidden = false;

        // eslint-disable-next-line eqeqeq
        if (pendingDocuments == []) return true;

        let firstCertFound = null;

        for (var doc in pendingDocuments) {
            var docHasCertificate = this.docHasCertificate(pendingDocuments[doc].SignerDTO.CentralizedCertificateInfo);
            if (docHasCertificate !== null && firstCertFound != null && firstCertFound !== docHasCertificate) {
                hidden = true;
                break;
            }
            if (!firstCertFound && docHasCertificate) firstCertFound = docHasCertificate;
        }

        return hidden;
    }

    handleOnSelect = (row, isSelect, source = DOCUMENTS_LIST) => {
        if (isSelect) {
            return this.selectDocument(row, source);
        }

        this.unselectDocuments([row]);
    };

    handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            rows.forEach((row, i) => this.selectDocument(row, i === (rows.length - 1))
            );
            return;
        }

        this.unselectDocuments(rows);
    }

    selectDocument(row, source) {
        const { docsSelected, docsSelectedSignerGUI, docCertSelected } = this.state;
        const { filters } = this.props;
        let newDocumentState = {};

        const docHasCertificate =  this.docHasCertificate(row.SignerDTO.CentralizedCertificateInfo);

        // docHasCertificate could be null when de doc does not have certificate defined
        if ((docCertSelected !== docHasCertificate) && docHasCertificate) {
            newDocumentState.docCertSelected = docHasCertificate;
        }

        if (docsSelectedSignerGUI.indexOf(row.SignerDTO.SignerGUI) === -1) {
            docsSelectedSignerGUI.push(row.SignerDTO.SignerGUI);
        }
        if (docsSelected.find(doc => doc.SignerDTO.signerGUI === row.SignerDTO.SignerGUI) === undefined) {
            docsSelected.push(row);
        }

        newDocumentState.docsSelectedSignerGUI = docsSelectedSignerGUI;
        newDocumentState.docsSelected = docsSelected;

        sessionStorage.setItem('docsSelectedSignerGUIString', docsSelectedSignerGUI.toString());
        this.setState(newDocumentState);

        if (docHasCertificate && !filters.CertificateGuid && source !== PREVIEW) {
            const { Guid } = row.SignerDTO.CentralizedCertificateInfo;

            this.props.dispatch({ 
                type: DocumentsActionTypes.MODIFY_FILTERS, 
                payload: {
                    ...this.props.filters,
                    CertificateGuid: Guid,
                }
              });
        }
    }

    getFilteredPendindSignaturesByCertificate(pendingSignatures, docHasCertificate) {
        return pendingSignatures.filter((doc => {
            return !this.filteredTheDifferentCertificate(doc.SignerDTO.CentralizedCertificateInfo, docHasCertificate);
        }));
    }

    getCurrentTablePage(pendingSignatures, signerGuid, currentSizePerPageTable, isLastDocumentSelected, docsSelected) {
        // Sort table by FileName asc
        const lastAddedDocGuidIndex = pendingSignatures.findIndex(doc => doc.SignerDTO.signerGUI === signerGuid);

        let firstAddedDocGuidIndex;
        // This only runs when I select multiple docs and this is the last one
        if (isLastDocumentSelected) {
            const firstDocSelected = docsSelected[0];
            firstAddedDocGuidIndex = pendingSignatures.findIndex(doc => doc.SignerDTO.signerGUI === firstDocSelected.SignerDTO.SignerGUI);
        }

        const docIndexToBeFound = isLastDocumentSelected ? firstAddedDocGuidIndex : lastAddedDocGuidIndex;

        const module = docIndexToBeFound % currentSizePerPageTable;
        const page = Math.ceil(docIndexToBeFound / currentSizePerPageTable);

        return module === 0 ? page + 1 : page;
    }

    docHasCertificate(CentralizedCertificateInfo) {
        if (!CentralizedCertificateInfo || !CentralizedCertificateInfo.Guid)
            return null;

        return CentralizedCertificateInfo.Guid;
    }

    unselectDocuments(rows) {
        let { docsSelected, docsSelectedSignerGUI } = this.state;

        const docsSignersGuidsToUnselect = rows.map((doc) => doc.SignerDTO.SignerGUI);

        docsSelected = docsSelected.filter((selectedDoc) => {
            return !docsSignersGuidsToUnselect.includes(selectedDoc.SignerDTO.SignerGUI)
        });

        docsSelectedSignerGUI = docsSelected.map((doc) => doc.SignerDTO.SignerGUI);

        sessionStorage.setItem('docsSelectedSignerGUIString', docsSelectedSignerGUI.toString());
        this.setState({ docsSelectedSignerGUI, docsSelected });
    }

    filteredTheDifferentCertificate(CentralizedCertificateInfo, vdocCertSelected) {
        var docCertGUID = this.docHasCertificate(CentralizedCertificateInfo);

        if (!docCertGUID)
            return false;

        if (vdocCertSelected !== docCertGUID)
            return true;
    }

    loadViDMobileUserCertificateList() {
        const _this = this;

        this.loadUserData()
            .then(res => {
                if (res) {
                    _this.setState({ userData: res });
                    _this.loadViDMobileUserCertificateTypes()
                        .then(res => {
                            res.Nebula = true;
                            _this.setState({ userCertificatesAuth: res });
                            _this.getUserCertificates()
                                .then(res => {
                                    if (_this.state.userCertificatesAuth === null) {
                                        var ViDMobileUserAllowSWCerts = true;
                                    } else {
                                        ViDMobileUserAllowSWCerts = _this.state.userCertificatesAuth.Software;
                                        var ViDMobileUserAllowFirmaRemotaCerts = _this.state.userCertificatesAuth.FirmaRemota;
                                        var ViDMobileUserAllowKeyVaultCerts = _this.state.userCertificatesAuth.KeyVault;
                                        var ViDMobileUserAllowRTCerts = _this.state.userCertificatesAuth.RedTrust;
                                        var ViDMobileUserAllowUTCerts = _this.state.userCertificatesAuth.Uanataca;
                                        var ViDMobileUserAllowSwisscomCerts = _this.state.userCertificatesAuth.Swisscom;
                                        var ViDMobileUserAllowAutofirma = _this.state.userCertificatesAuth.Local;
                                        var ViDMobileUserAllowNebulaCerts = _this.state.userCertificatesAuth.Nebula;
                                        var ViDMobileUserAllowValidatedIdCerts = _this.state.userCertificatesAuth.ValidatedID;
                                    }
                                    var userCertsFiltered = res.reduce(function (userCertsAllowed, certificate) {
                                        if ((certificate.CertType === "FirmaRemota" && ViDMobileUserAllowFirmaRemotaCerts) ||
                                            (certificate.CertType === "KeyVault" && ViDMobileUserAllowKeyVaultCerts) ||
                                            (certificate.CertType === "Software" && ViDMobileUserAllowSWCerts) ||
                                            (certificate.CertType === "RedTrust" && ViDMobileUserAllowRTCerts) ||
                                            (certificate.CertType === "Uanataca" && ViDMobileUserAllowUTCerts) ||
                                            (certificate.CertType === "Nebula" && ViDMobileUserAllowNebulaCerts) ||
                                            (certificate.CertType === "ValidatedID" && ViDMobileUserAllowValidatedIdCerts)) {
                                            userCertsAllowed.push(certificate);
                                        }
                                        return userCertsAllowed;
                                    }, []);
                                    if (ViDMobileUserAllowSwisscomCerts) {
                                        userCertsFiltered.push({
                                            CertType: "Swisscom",
                                            ExpirationDate: "-",
                                            certalias: "Swisscom"
                                        });
                                    }
                                    if (ViDMobileUserAllowAutofirma) {
                                        userCertsFiltered.push({
                                            CertType: "Autofirma",
                                            ExpirationDate: "-",
                                            certalias: "Autofirma"
                                        });
                                    }
                                    _this.setState({ userCertsFiltered: userCertsFiltered });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    console.log('no user data')
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    filterCertsByDocCert() {
        const { t } = this.props;
        const { userCertsFiltered, docCertSelected, docsSelectedSignerGUI, userCertificatesAuth } = this.state;
        var vuserCertsFiltered = userCertsFiltered;
        if (docCertSelected !== null && userCertsFiltered) {
            vuserCertsFiltered = userCertsFiltered.reduce(function (userCertsAllowed, certificate) {
                if ((certificate.GUID === docCertSelected)) {
                    userCertsAllowed.push(certificate);
                }
                return userCertsAllowed;
            }, []);
        }
        if (typeof vuserCertsFiltered !== 'undefined' && vuserCertsFiltered.length > 0) {
            if (vuserCertsFiltered.length === 1) {
                this.handleCertSelection(vuserCertsFiltered[0], []);
                return
            } 
            var certificatesList = vuserCertsFiltered.map((certificate, index) => {
                return (
                    <ListGroup.Item
                        key={certificate.GUID + certificate.certalias + index}
                        action
                        variant="light"
                        onClick={() => this.handleCertSelection(certificate, docsSelectedSignerGUI)}
                    >
                        <ul className="list-container">
                            {
                                certificate.CertType === "FirmaRemota" || certificate.certalias !== "Swisscom" ?
                                    <>
                                        <li>{t('general.alias')}: {(certificate.AuthenticationType === 'OTP' || (certificate.DN && certificate.CertType.toLowerCase() === VALIDATEDID) ) && certificate.DN} {certificate.AuthenticationType !== 'OTP' && certificate.certalias}</li>
                                        <li>{t('general.type')}: {certificate.CertType === "FirmaRemota" && "itAgile"} {certificate.certalias !== "Swisscom" && certificate.CertType} </li>
                                        <li>{t('general.expiration-date')}: {certificate.ExpirationDate}</li>
                                    </>
                                    :
                                    <li>{docsSelectedSignerGUI.length > 1 ? certificate.certalias + t('documents.certificate-unavailable') : certificate.certalias}</li>
                            }
                        </ul>
                    </ListGroup.Item>
                )
                });
                this.setState({ certificatesList: certificatesList, showSignModal: true });
            return;
        }

         // Show Autofirma cert by default
         if (userCertificatesAuth.Local) {
            const certificate = {
                CertType: "Autofirma"
            };
            const certificatesList = <>
                <ListGroup.Item
                    key={"Autofirma"}
                    action
                    variant="light"
                    onClick={() => this.handleCertSelection(certificate)}
                >
                    <ul className="list-container">
                        <li>{t('general.alias')}: Autofirma</li>
                        <li>{t('general.type')}: Autofirma </li>
                        <li>{t('general.expiration-date')}: --</li>
                    </ul>
                </ListGroup.Item>
                </>
            this.setState({ certificatesList, showSignModal: true });
            return;
        }

        this.setState({
            certificatesList: <p>{t('documents.cert-type-selection-empty')}</p>,
            showSignModal: true
        });
    }

    handleCertSelection(certificate, docsSelected) {
        var showKeyVaultModal = false;
        var showFirmaRemotaModal = false;
        var showSoftwareModal = false;
        var showRedTrustModal = false;
        var showNebulaModal = false;
        var showSwisscomModal = false;
        var showAutofirmaModal = false;
        var showNoPinConfirmationModal = false;
        var showWarningModal = false;
        let preventBatchModal = false;
        if (checkShowNoPinConfirmationModal(certificate)) {
            showNoPinConfirmationModal = true;
            this.setState({ nebulaPinRequired: false });
        }
        else {
            if (certificate.CertType === "KeyVault" && certificate.AuthenticationType === "PIN") {
                showKeyVaultModal = true;
            }
            if (certificate.CertType === "KeyVault" && certificate.AuthenticationType === "SMS") {
                showKeyVaultModal = true;
                this.keyVaultSendOTP(certificate);
            }
            if (certificate.CertType === "FirmaRemota" && certificate.AuthenticationType === "APP") {
                showFirmaRemotaModal = true;
            }
            if (certificate.CertType === "FirmaRemota" && certificate.AuthenticationType === "SMS") {
                showFirmaRemotaModal = true;
                this.firmaRemotaSendOTP();
            }
            if (certificate.CertType === "Software") {
                showSoftwareModal = true;
            }
            if (certificate.CertType === "RedTrust") {
                showRedTrustModal = true;
            }
            if (certificate.CertType === "Uanataca") {
              this.isQualifiedCertificate(certificate.AuthenticationType);
            }
            if (certificate.CertType === "ValidatedID") {
                this.isValidatedIdCertificateQualified(certificate.AuthenticationType);
            }
            if (certificate.CertType === "Swisscom" && docsSelected.length === 1) {
              showSwisscomModal = true;
            }
            if (certificate.CertType === "Autofirma") {
                if (docsSelected.length > 1) {
                    preventBatchModal = true;  
                } else {
                    showAutofirmaModal = true;
                    this.signDocAutofirma();
                }
           
            }
            if (certificate.CertType === "Nebula") {
              this.getAuthenticators();
              showNebulaModal = true;
            }
            if (certificate.CertType === "Swisscom" && docsSelected.length > 1) {
              preventBatchModal = true; 
            //   showWarningModal = true;
            }
        }
        this.setState({
            showKeyVaultModal: showKeyVaultModal,
            showFirmaRemotaModal: showFirmaRemotaModal,
            showSoftwareModal: showSoftwareModal,
            showRedTrustModal: showRedTrustModal,
            showSwisscomModal: showSwisscomModal,
            showAutofirmaModal: showAutofirmaModal,
            showNebulaModal: showNebulaModal,
            showNoPinConfirmationModal: showNoPinConfirmationModal,
            showSignModal: false,
            certificateSelected: certificate,
            showWarningModal: showWarningModal,
            selectedCertificate: certificate.CertType,
            preventBatchModal
        });
    }

  isQualifiedCertificate(authenticationType) {
    const _this = this;
    if (authenticationType === OTP) {
      _this.setState({ isUanatacaQualified: true });
      const { oauthTokenB64 } = this.state;
      var viDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

      postManagementSignature(generateUanatacaOtpUri(viDMobileUserGUID), '', oauthTokenB64)
        .then(res => {
          _this.setState({ showUanatacaModalOtp: true });
        })
        .catch(function (error) {
          _this.handleSignatureError(error, UANATACA_CERTIFICATE_PIN_KEY);
        });

      return;
    }

    _this.setState({ showUanatacaModalPin: true });
  }

    isValidatedIdCertificateQualified(authenticationType) {
        const _this = this;
        if (authenticationType === OTP) {
            _this.setState({ isValidatedIdQualified: true });
            const { oauthTokenB64 } = this.state;
            var viDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

            postManagementSignature(generateValidatedIdOtpUri(viDMobileUserGUID), '', oauthTokenB64)
                .then(res => {
                    _this.setState({ showValidatedIdModalOtp: true });
                })
                .catch(function (error) {
                    _this.handleSignatureError(error, VALIDATEDID_CERTIFICATE_PIN_KEY);
                });

            return;
        }

        _this.setState({ showValidatedIdModalPin: true });
    }

    getAuthenticators() {
      const _this = this;
      _this.setState({ loadingNebula: true });
      const { oauthTokenB64 } = this.state;
      var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

      const dataJson = {
        IsSign: true
      };

      // Reset Nebula PIN when requesting authenticators
      _this.setState({
        certificateNebulaPin: ''
      });


      postManagementSignature(generateNebulaAuthUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
          .then(res => {
              this.setState({ loadingNebula: false });
              this.authenticators = res.data.Authenticators;

              if (this.authenticators.length === 1) {
                _this.setState({
                  nebulaStep1: false,
                  nebulaStep2: true,
                  NBauthenticator: this.authenticators[0]
                });
                _this.sendAuthenticator();
              } else {
                _this.setState({
                  nebulaStep1: true,
                  NBAuthenticator: this.state.NBAuthenticator
                });
              }
          })
          .catch(function (error) {
            this.setState({ loadingNebula: false });
            _this.handleGenericError(error);
          });
    }

    handleAuthenticatorChange (e) {
      this.setState({ NBauthenticator: e.target.value })
    }

    sendAuthenticator() {
      const _this = this;
      const { oauthTokenB64, NBauthenticator } = this.state;
      var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

      const dataJson = {
        Authenticator: NBauthenticator,
      };

      postManagementSignature(generateNebulaAuthMiddleUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
          .then(res => {
              _this.setState({
                nebulaStep1: false,
                nebulaStep2: true
              });
          })
          .catch(function (error) {
              _this.handleGenericError(error);
          });
    }

    sendChallenge() {
      const _this = this;
      _this.setState({ loadingNebula: true, nebulaStep2: false });
      const { oauthTokenB64, NBChallenge, nebulaPinRequired } = this.state;
      var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

      const dataJson = {
        Challenge: NBChallenge,
        };

      postManagementSignature(generateNebulaAuthEndUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
          .then(res => {
            if (!nebulaPinRequired) {
              _this.signDocSelection('nebula');
            }
            _this.setState({
              nebulaStep1: false,
              nebulaStep2: false,
              nebulaStep3: true,
              NBChallenge: '',
              loadingNebula: false,
            });
          })
          .catch(function (error) {
            if (error.response.data.includes("integrationUnauthorized")) {
              _this.setState({
                showChallengeError: true,
                loadingNebula: false,
              });
            } else {
              /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
              _this.setState({ sessionExpired: true, loadingNebula: false, NBChallenge: '' });
              _this.startTimer();
            }
          });
    }

    signDocNebula() {
        const _this = this;
        const { oauthTokenB64, certificateSelected, certificateNebulaPin, docsSelected, docsSigned, accessMethod } = this.state;
        _this.setState({
            loadingSignatureProcess: true,
            showNebulacaModal: false,
            showNoPinConfirmationModal: false
        });

        const dataJson = {
            CertGUID: certificateSelected.GUID,
            PIN: certificateNebulaPin,
            CertType: certificateSelected.CertType,
            AccessMethod: accessMethod
        };

        postPrivSignature(generateNebulaUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleSignatureSuccess(res.status, NEBULA);
            })
            .catch(function (error) {
                _this.handleSignatureError(error, NEBULA_CERTIFICATE_PIN_KEY);
            })
    }

    focusInput(input) {
      /**The stimeout is necesary to wait until the DOM is loaded */
      if(input) setTimeout(function(){ input.focus(); }, 1);
    }

    loadUserData() {
        this.setState({ loading: true });
        const _this = this;
        const { oauthTokenB64 } = this.state;


        var config = {
            headers: {
                'Authorization': 'Bearer ' + oauthTokenB64
            }
        };
        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    _this.handleGenericError(error);
                    reject(error);
                });
        });
    }

    getUserCertificates() {
        const _this = this;
        const { userCertificatesAuth, userData, oauthTokenB64 } = _this.state;
        const { t } = _this.props;

        var config = { headers: { 'Authorization': 'Bearer ' + oauthTokenB64 } };

        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileusers/' + userData.legalid + '/certs', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    _this.handleSessionExpiredError(error.response.status);
                    if (error.response.status === 404) {
                        if (!userCertificatesAuth || (!userCertificatesAuth.Swisscom && !userCertificatesAuth.Autofirma)) {
                            _this.setState({ certificatesList: <p>{t('documents.cert-type-selection-empty')}</p> });
                            return;
                        }
                        if (userCertificatesAuth.Swisscom || userCertificatesAuth.Autofirma)
                            return resolve([]);
                    }

                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true
                    });
                    reject(error);
                });
        });
    }

    loadViDMobileUserCertificateTypes() {
        var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };

        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/certstype', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    this.handleGenericError(error);
                    reject(error);
                });
        });
    };

    handleSessionExpiredError(status) {
        if (status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            this.setState({ sessionExpired: true });
            this.startTimer();
        }
    }

    handleGenericError(error) {
        this.handleSessionExpiredError(error.response.status);

        if (error.response.status !== 401) {
            this.setState({
                errorType: error.response.status,
                errorData: error.response.data,
                showError: true
            });
        }
    }

    getViDMobileUserGUIDFromOauthToken() {
        const { oauthTokenB64 } = this.state;
        var tokenParts = oauthTokenB64.split(".");
        var jsonTokenInfo = atob(tokenParts[1]);
        var tokenInfo = JSON.parse(jsonTokenInfo);
        return tokenInfo.sub;
    }

    firmaRemotaSendOTP() {
        const _this = this;
        const { oauthTokenB64 } = this.state;
        var ViDMobileUserGUID = _this.getViDMobileUserGUIDFromOauthToken();

        putPrivSignature(generateFirmaRemotaOTPUri(ViDMobileUserGUID), "", oauthTokenB64)
            .then(res => {
                //firmaRemota SMS OTP sent
            })
            .catch(function (error) {
                _this.handleGenericError(error);
            });
    }

    keyVaultSendOTP(certificate) {
        const _this = this;
        const { oauthTokenB64, accessMethod } = this.state;

        const dataJson = {
            AccessMethod: accessMethod
        };
        
        postPrivSignature(generateKeyVaultSendOTPUri(certificate.GUID), dataJson, oauthTokenB64)
            .then(res => {
                //keyVault SMS OTP sent
            })
            .catch(function (error) {
                _this.handleGenericError(error);
            });
    }

    signDocSelection(type) {
        const { docsSelected, docsSigned, sessionOpened } = this.state;

        if (docsSigned !== docsSelected.length && docsSelected[docsSigned]) {
            if (type === SOFTWARE) {
                this.signDocSoftware();
            }
            if (type === KEYVAULT) {
                this.signDocKeyVault();
            }
            if (type === REDTRUST) {
                this.signDocRedTrust();
            }
            if (type === FIRMAREMOTA) {
                this.signDocFirmaRemota();
            }
            if (type === UANATACA) {
                this.signDocUanataca();
            }
            if (type === SWISSCOM) {
                this.signDocSwisscom();
            }
            if (type === NEBULA) {
              this.signDocNebula();
            }
            if (type === VALIDATEDID) {
                this.signDocValidatedId();
            }

            return;
        }

        if (sessionOpened) {
            this.firmaRemotaCloseSession();
        }

        sessionStorage.removeItem('docsSelectedSignerGUIString');

        this.props.dispatch({
            type: DocumentsActionTypes.MODIFY_PAGINATION,
            payload: {
                ...this.props.paginationMeta,
                PageNumber: 1,
                Count: this.props.paginationMeta.Count - (docsSigned + 1)
            },
        });

        this.setState({
            docsSelected: [],
            docsSelectedSignerGUI: [],
            loadingSignatureProcess: false,
            docsSigned: 0,
            certificateSoftwarePin: '',
            certificateKeyVaultPin: '',
            certificateFirmaRemotaPin: '',
            certificateRedTrustPin: '',
            certificateUanatacaPin: '',
            certificateUanatacaOtp: '',
            certificateValidatedIdPin: '',
            certificateValidatedIdOtp: '',
            certificateNebulaPin: '',
            signedDoc: true
        });

    }

    signDocSoftware() {
        const _this = this;
        const { oauthTokenB64, certificateSoftwarePin, docsSelected, docsSigned, accessMethod } = this.state;

        const dataJson = {
            PinAuthentication: certificateSoftwarePin,
            AccessMethod: accessMethod           
        };

        postPrivSignature(generateSoftwareUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleSignatureSuccess(res.status, SOFTWARE);
            })
            .catch(function (error) {
                _this.handleSignatureError(error, SOFTWARE_CERTIFICATE_PIN_KEY);
            });
    }

    signDocKeyVault() {
        const _this = this;
        const { oauthTokenB64, certificateSelected, certificateKeyVaultPin, docsSelected, docsSigned, accessMethod } = this.state;

        const dataJson = {
            CertGUID: certificateSelected.GUID,
            PIN: certificateKeyVaultPin,
            CertType: certificateSelected.CertType,
            AccessMethod: accessMethod
        };
        
        postPrivSignature(generateKeyVaultUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleSignatureSuccess(res.status, KEYVAULT);
            })
            .catch(function (error) {
                _this.handleSignatureError(error, KEYVAULT_CERTIFICATE_PIN_KEY);                
            });
    }

    signDocFirmaRemota() {
        const _this = this;
        const { oauthTokenB64, certificateSelected, certificateFirmaRemotaPin, docsSelected, docsSigned, accessMethod } = this.state;

        if (certificateFirmaRemotaPin !== "" && docsSigned === 0) {
            _this.firmaRemotaOpenSession();
        }

        const dataJson = {
            CertGUID: certificateSelected.GUID,
            PIN: certificateFirmaRemotaPin,
            CertType: certificateSelected.CertType,
            AccessMethod: accessMethod
        };

        postPrivSignature(generateFirmaRemotaUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleSignatureSuccess(res.status, FIRMAREMOTA);
            })
            .catch(function (error) {
                _this.handleSignatureError(error, FIRMAREMOTA_CERTIFICATE_PIN_KEY);                
            });
    }

    handleNoPinDocSignature() {
        const { certificateSelected } = this.state;
        this.setState({
            loadingSignatureProcess: true,
            showNoPinConfirmationModal: false
        });
        if (certificateSelected.CertType === "KeyVault") {
            this.signDocSelection('keyvault')
        }
        if (certificateSelected.CertType === "FirmaRemota") {
            this.signDocSelection('firmaremota')
        }
        if (certificateSelected.CertType === "Software") {
            this.signDocSelection('software')
        }
        if (certificateSelected.CertType === "RedTrust") {
            this.signDocSelection('redtrust')
        }
        if (certificateSelected.CertType === "Uanataca") {
            this.signDocSelection('uanataca')
        }
        if (certificateSelected.CertType === "ValidatedID") {
            this.signDocSelection('validatedid')
        }
        if (certificateSelected.CertType === "Nebula") {
          this.setState({ loadingSignatureProcess: false, showNebulaModal: true });
          this.getAuthenticators();
        }
    }

    firmaRemotaOpenSession() {
        const _this = this;
        const { oauthTokenB64, certificateFirmaRemotaPin } = this.state;

        const dataJson = {
            OTP: certificateFirmaRemotaPin
        };

        putManagementSignature(generateFirmaRemotaSessionUri(), dataJson, oauthTokenB64)
        .then(() => {
                _this.setState({ sessionOpened: true });
            })
            .catch(function (error) {
                _this.handleGenericError(error);
            });
    }

    firmaRemotaCloseSession() {
        const _this = this;
        const { oauthTokenB64, certificateFirmaRemotaPin } = this.state;

        const dataJson = {
            OTP: certificateFirmaRemotaPin
        };

        deleteManagementSignature(generateFirmaRemotaSessionUri(), dataJson, oauthTokenB64)
            .catch(function (error) {
                _this.handleGenericError(error);
            })
            .finally(() => {
                _this.setState({ certificateFirmaRemotaPin: '' });
            });
    }

    signDocRedTrust() {
        const _this = this;
        const { oauthTokenB64, certificateSelected, certificateRedTrustPin, docsSelected, docsSigned, accessMethod} = this.state;

        const dataJson = {
            CertGUID: certificateSelected.GUID,
            PIN: certificateRedTrustPin,
            CertType: certificateSelected.CertType,
            AccessMethod: accessMethod
        };
        
        postPrivSignature(generateRedTrustUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleSignatureSuccess(res.status, REDTRUST);
            })
            .catch(function (error) {
                _this.handleSignatureError(error, REDTRUST_CERTIFICATE_PIN_KEY);                
            });
    }

    handleSignatureSuccess(status, signatureName) {
        const { docsSelected, docsSigned } = this.state;

        const { PageSize } = this.props.paginationMeta;

        if (status === 204) {
            const signerGuid = docsSelected[docsSigned].SignerDTO.SignerGUI;
            Utils.removeItemFromSelectedDocsInSessionByGuid(signerGuid);
            
            var targetState = { docsSigned: this.state.docsSigned + 1 };
            
            //Get index of the first doc to be signed
            let firstDocToBeSignedIndex = this.props.pendingDocuments.findIndex((doc) => {
                return doc.SignerDTO.signerGUI === docsSelected[0].SignerDTO.SignerGUI;
            });

            firstDocToBeSignedIndex++;

            //Calculates the page the first signed doc is
            const firstSignedPage = Math.floor(firstDocToBeSignedIndex / PageSize) + 1;

            //See if doc is the first of that page
            const isFirstDocOfThePage = (firstDocToBeSignedIndex % PageSize) === 1;

            //If is in first page, remain there. Otherwise, if page disappears after signed doc, return to previous page
            targetState.currentPageTable = firstSignedPage === 1 ? 1 : isFirstDocOfThePage ? firstSignedPage - 1 : firstSignedPage;

            this.setState(targetState, () => {
                this.signDocSelection(signatureName);
            });
        }
    }

    handleSignatureError(error, certificatePinKey) {
        if (error.response.status === 401) {
            this.setState({ sessionExpired: true });
            this.startTimer();
        } else {
            this.setState({
                loadingSignatureProcess: false,
                errorType: error.response.status,
                errorData: error.response.data,
                showError: true,
                [certificatePinKey]: '',
                docsSelected: [],
                docsSelectedSignerGUI: [],
                docsSigned: 0
            });

            this.getPendingDocuments();
        }
    }

    signDocUanataca() {
        const _this = this;
        const { oauthTokenB64, certificateSelected, certificateUanatacaPin, docsSelected, docsSigned, certificateUanatacaOtp, isUanatacaQualified, accessMethod } = this.state;

        var dataJson = {
            CertGUID: certificateSelected.GUID,
            PIN: certificateUanatacaPin,
            CertType: certificateSelected.CertType,
            AccessMethod: accessMethod
        };

        if (isUanatacaQualified) {
            const dataJsonQF = Object.assign(dataJson, { OTP: certificateUanatacaOtp });
            dataJson = dataJsonQF;
        }
        
        postPrivSignature(generateUanatacaUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleSignatureSuccess(res.status, UANATACA);
            })
            .catch(function (error) {
                _this.handleSignatureError(error, UANATACA_CERTIFICATE_PIN_KEY);                
            });
    }

    signDocValidatedId() {
        const _this = this;
        const { oauthTokenB64, certificateSelected, certificateValidatedIdPin, docsSelected, docsSigned, certificateValidatedIdOtp, isValidatedIdQualified, accessMethod } = this.state;

        var dataJson = {
            CertGUID: certificateSelected.GUID,
            PIN: certificateValidatedIdPin,
            CertType: certificateSelected.CertType,
            AccessMethod: accessMethod
        };

        if (isValidatedIdQualified) {
            const dataJsonQF = Object.assign(dataJson, { OTP: certificateValidatedIdOtp });
            dataJson = dataJsonQF;
        }

        postPrivSignature(generateValidatedIdUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleSignatureSuccess(res.status, VALIDATEDID);
            })
            .catch(function (error) {
                _this.handleSignatureError(error, VALIDATEDID_CERTIFICATE_PIN_KEY);
            });
    }

    signDocSwisscom() {
        const _this = this;
        const { oauthTokenB64, docsSelected, docsSigned, accessMethod } = this.state;
        const dataJson = {
            AccessMethod: accessMethod
        };

        postPrivSignature(generateSwisscomUri(docsSelected[docsSigned].SignerDTO.SignerGUI), dataJson, oauthTokenB64)
            .then(res => {
                var swisscomReturnObj = res.data;
                var isUrlDefined = swisscomReturnObj.RedirectURL ? true : false;
                if (isUrlDefined) {
                    Utils.openTab(swisscomReturnObj.RedirectURL);
                }

                _this.setState({ 
                    swisscomRedirectURL: swisscomReturnObj.RedirectURL, 
                    swisscomRequestID: swisscomReturnObj.RequestID, 
                    loadingSwisscomRequest: false 
                });
            })
            .catch(function (error) {
                _this.handleSessionExpiredError(error.response.status);
                if (error.response.status !== 401) {
                    _this.handleSwisscomErrorMessage(error);
                }
            });
    }

    completeSwisscomSignature() {
        const _this = this;
        const { t } = this.props;
        const { oauthTokenB64, docsSelected, docsSigned, swisscomRequestID, accessMethod } = this.state;
        const dataJson = {
            AccessMethod: accessMethod
        }

        postPrivSignature(generateSwisscomUri(docsSelected[docsSigned].SignerDTO.SignerGUI, swisscomRequestID), dataJson, oauthTokenB64)
            .then(res => {
                sessionStorage.removeItem('docsSelectedSignerGUIString');
                _this.setState({
                    docsSelected: [],
                    docsSigned: 0,
                    signedDoc: true,
                    loadingSwisscomCheck: false,
                    showSwisscomConfirmationModal: false
                }, () => _this.getPendingDocuments());
            })
            .catch(function (error) {
                if (error.response) {
                    _this.handleSessionExpiredError(error.response.status);
                    if (error.response.status === 409) {
                        _this.setState({
                            loadingSwisscomCheck: false,
                            swisscomError: t('documents.swisscom-error')
                        });
                    } 
                    if(error.response.status !== 401 && error.response.status !== 409) {
                        _this.handleSwisscomErrorMessage(error);
                    }
                }
                else {
                    error.response.status = "Undefined";
                    error.response.data = "Network error";
                    _this.handleSwisscomErrorMessage(error);
                }
            });
    }

    handleSwisscomErrorMessage(error) {
        this.setState({
            errorType: error.response.status,
            errorData: error.response.data,
            showError: true,
            showSwisscomConfirmationModal: false,
            showSwisscomModal: false,
            loadingSwisscomCheck: false,
            loadingSwisscomRequest: false
        });
    }

    signDocAutofirma() {
        this.loadAppAFirma().then(() => {
            this.doSelectCert();
        });
    }

    loadAppAFirma() {
        return new Promise((resolve, reject) => {
            window.AutoScript.cargarAppAfirma();
            window.AutoScript.setStickySignatory(true);
            setTimeout(() => { resolve(); }, 1500);
        });
    }

    doSelectCert() {
        try {
            window.AutoScript.selectCertificate(
                "",
                this.showCertCallback.bind(this),
                this.showErrorCallback.bind(this)
            );

        } catch (e) {
            console.log("Type: " + window.AutoScript.getErrorType() + "\nMessage: " + window.AutoScript.getErrorMessage());
            this.setState({ showAutofirmaModal: false });
        }
    }

    calculateAutofirmaPercentage(step){
        const {docsSelected, currentDataAutofirma} = this.state;
        let index = currentDataAutofirma.index != null ? (currentDataAutofirma.index + 1) : 1;

        // total is 5 steps times document
        const totalSteps = docsSelected.length * 5;

        // calculate completed steps
        const completedDocs = index - 1;
        const completedPreviousDocsSteps = completedDocs * 5;
        const completedSteps = completedPreviousDocsSteps + step;

        // percentage based on steps
        const percentage = (100 * completedSteps / totalSteps);
        
        return Math.floor(percentage);
    }

    // FUNCION DE FIRMA
    doSign(data) {
        const _this = this;
        const { oauthTokenB64, accessMethod } = this.state;

        this.setState({ currentDataAutofirma: data, autofirmaPercentage: this.calculateAutofirmaPercentage(2) });

        const dataJson = {
            CertType: data.certType,
            CertsData: [data.certsData],
            HashAlgorithm: data.hashAlgorithm,
            AccessMethod: accessMethod
        };

        postPrivSignature(generateAutofirmaCertificateUri(data.signerGUI), dataJson, oauthTokenB64)
            .then(res => {
                if (res.status === 204) {
                    getPrivSignature(generateAutofirmaPreSignatureUri(data.signerGUI), oauthTokenB64)
                        .then(response => {
                            _this.setState({ autofirmaPercentage: this.calculateAutofirmaPercentage(3) });
                            try {
                                /* SE FILTRA POR EL CN SELECCIONADO PREVIAMENTE Y
                                 * SE ACTIVA EL HEADLESS PARA EVITAR QUE VUELVA A SOLICITAR EL CERTIFICADO
                                 * */
                                window.AutoScript.sign(
                                    response.data.PreSignatureContentDNIe,
                                    "SHA512withRSA",
                                    "NONE",
                                    null,
                                    this.showSignResultCallback.bind(this),
                                    this.showErrorCallback.bind(this));
                                  
                            } catch (e) {
                                _this.setState({ loadingSignatureProcessAutofirma: false });
                                try {
                                    console.log("Type: " + window.AutoScript.getErrorType() + "\nMessage: " + window.AutoScript.getErrorMessage());
                                } catch (ex) {
                                    console.log("Error: " + e);
                                }
                            }
                        })
                        .catch(function (error) {
                            _this.handleAutofirmaSignatureError(error);
                        });
                }
            })
            .catch(function (error) {
                _this.handleAutofirmaSignatureError(error);
            });
    }

    handleAutofirmaSignatureError = (error) => {
        const { t } = this.props;

        this.handleSessionExpiredError(error.response.status);
        
        this.setState({
            loadingSignatureProcessAutofirma: false,
            showAutofirmaModal: false,
            errorType: t('documents.autofirma-title'),
            errorData: error.message,
            showError: true
        });
    }

    // FUNCION CALLBACK DE AUTOFIRMA PARA ENVIAR EL RESULTADO DE LA FIRMA
    showSignResultCallback(signatureB64) {
        const _this = this;
        const { oauthTokenB64, docsSelected, clientIp, ipData, currentDataAutofirma, accessMethod} = this.state;
        const evidenceLocation = (ipData.latitude + ipData.longitude).toString;
        const browserStringified = JSON.stringify(browser);
        this.setState({ autofirmaPercentage: this.calculateAutofirmaPercentage(4) });

        const dataJson = {
            Signature: signatureB64,
            HashAlgorithm: "SHA512",
            IpAddress: clientIp,
            Location: evidenceLocation,
            From: browserStringified,
            AccessMethod: accessMethod
        };

        postPrivSignature(generateAutofirmaUri(currentDataAutofirma.signerGUI), dataJson, oauthTokenB64)
            .then(res => {
                if(currentDataAutofirma.index < docsSelected.length - 1 ){
                    currentDataAutofirma.index++;
                    currentDataAutofirma.signerGUI = docsSelected[currentDataAutofirma.index].SignerDTO.SignerGUI;
                    
                    this.doSign(currentDataAutofirma);
                    return;
                }

                sessionStorage.removeItem('docsSelectedSignerGUIString');
                  _this.setState({
                      autofirmaPercentage: this.calculateAutofirmaPercentage(5),
                      docsSigned: 0
                  }, () => this.closeAutofirmaProcess());
            })
            .catch(function (error) {
                _this.handleAutofirmaSignatureError(error);
            });
    }

    closeAutofirmaProcess() {
        const _this = this;
        setTimeout(() => {
            _this.setState({
                docsSelected: [],
                loadingSignatureProcessAutofirma: false,
                showAutofirmaModal: false,
                signedDoc: true
            }, () => this.getPendingDocuments());
        }, 1000);
    }

    // FUNCION CALLBACK DE AUTORFIRMA PARA RECOGER EL CERTIFICADO A USAR
    showCertCallback(certificateB64) {
        const { docsSelected, docsSigned } = this.state;
        
        var pki = window.forge.pki;
        var certPEM = "-----BEGIN CERTIFICATE-----\n" + certificateB64 + "\n-----END CERTIFICATE-----";
        var cert = pki.certificateFromPem(certPEM);
        var CN = cert.subject.getField('CN').value;

        var data = {
            signerGUI: docsSelected[docsSigned].SignerDTO.SignerGUI,
            certType: docsSelected[docsSigned].SignerDTO.TypeOfID,
            certsData: certificateB64,
            hashAlgorithm: "SHA512",
            cn: CN,
            index: 0
        };

        this.setState({
            loadingSignatureProcessAutofirma: true,
            autofirmaPercentage: this.calculateAutofirmaPercentage(1)
        });
        
        this.doSign(data);
    }

    showErrorCallback(errorType, errorMessage) {
        const { t } = this.props;
        /*if (errorType == "es.gob.afirma.standalone.ApplicationNotFoundException") {
         console.log("Autofirma application is not installed.");
         }
         else {
         console.log("Type: " + errorType + "\nMessage: " + errorMessage);
         }*/
        this.setState({
            showAutofirmaModal: false,
            errorType: t('documents.autofirma-title'),
            errorData: <p>{t('documents.autofirma-error')} <a href='https://firmaelectronica.gob.es/Home/Descargas.html' rel="noopener noreferrer" target='_blank'>https://firmaelectronica.gob.es/Home/Descargas.html</a></p>,
            showError: true
        });
    }

    getClientData() {
        const _this = this;
        (async () => {
            const clientIp = await publicIp.v4();
            const ipData = await ipLocation(clientIp);
            _this.setState({
                clientIp: clientIp,
                ipData: ipData
            });
        })();
    }

    seeDocument(e, row) {
        e.stopPropagation();
        this.setState({ viewDocument: true, selectedDoc: row, loading: true }, () => this.renderDocument())
    }

    seeNextDocument(e) {
        var { nextDocument } = this.state;
        this.setState({
            renderedDoc: {
                pages: [],
                totalPages: 0,
                nextPage: 1
            },
        });
        e.stopPropagation();
        this.setState({ viewDocument: true, selectedDoc: nextDocument, loading: true }, () => this.renderDocument());
    }

    handlePreviouslyRejectedDocError(error, isSigning = false) {
        if (error.response?.status !== 409) return;
        if (isSigning) {
            setTimeout(() => {
                this.setState({
                    viewDocument: false,
                    selectedDoc: {}
                });
                this.getPendingDocuments();
            }, 3000);
            return;
        } 
        this.setState({ previouslyRejectedDoc: true, selectedDoc: {} });
    }

    getPendingDocuments() {
        this.clearVariablesToRenderListProperly();
        var { userToken, urlReturn } = this.state;

        this.setState({ loading: true })

        const _this = this;

        const { filters, paginationMeta, ordering } = this.props;

        getPendingDocumentsRequest(filters, paginationMeta, ordering)
            .then(res => {
                if (res) {
                    const pendingSignatures = res.data.List;
                    const { Count, TotalPages, PageNumber } = res.data;

                    this.props.dispatch({
                        type: DocumentsActionTypes.MODIFY_PAGINATION,
                        payload: {
                            ...this.props.paginationMeta,
                            Count, 
                            TotalPages,
                        }
                    });

                    this.props.dispatch({
                        type: DocumentsActionTypes.ADD_DOCUMENTS_TO_LIST,
                        payload: pendingSignatures
                    });
                    if (pendingSignatures.length === 1 && PageNumber === 1) {
                        _this.setState({ viewDocument: true, selectedDoc: pendingSignatures[0] });
                        this.renderDocument();
                        return;
                    }
                    //aquí debe hacerse el map de pendingsignatures y ver si hay certificados apra mostrar la columna
                    for (var i = 0; i < pendingSignatures.length; i++) {
                        if (pendingSignatures[i].SignerDTO.CentralizedCertificateInfo) {
                            if (pendingSignatures[i].SignerDTO.CentralizedCertificateInfo.Guid !== null) {
                                _this.setState({ certHidden: false });
                                break;
                            }
                            else {
                                _this.setState({ certHidden: true });
                            }
                        }
                        else {
                            _this.setState({ certHidden: true });
                        }
                    }

                    _this.setPendingSignaturesSavedInSession();
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                    if (typeof userToken !== "undefined" && userToken === "true" && typeof urlReturn !== "undefined" && urlReturn !== "null" && urlReturn !== "") {
                        _this.setState({
                            tokenExpired: true
                        });
                        setTimeout(() => {
                            window.location.href = atob(urlReturn);
                        }, 3000);
                    }
                    else {
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    }
                }
                // Handle empty documents error
                if (error.response.status === 404) {
                    _this.props.dispatch({
                        type: DocumentsActionTypes.ADD_DOCUMENTS_TO_LIST,
                        payload: []
                    });
                    _this.setState({ loading: false })

                    if (typeof userToken !== "undefined" && userToken === "true" && typeof urlReturn !== "undefined" && urlReturn !== "null" && urlReturn !== "") {
                        _this.setState({
                            redirecting: true
                        });
                        setTimeout(() => {
                            window.location.href = atob(urlReturn);
                        }, 3000);
                        sessionStorage.clear();
                    }
                }

                _this.setState({ loading: false });
                _this.clearVariablesToRenderListProperly();
            });
    }

    clearVariablesToRenderListProperly() {
        this.setState({
            renderedDoc: {
                pages: [],
                totalPages: 0,
                nextPage: 1
            },
            selectedDoc: {},
            docCertSelected: null,
        });
    }

    setPendingSignaturesSavedInSession() {
        
        const { pendingDocuments } = this.props;

        const selectedCertificate = sessionStorage.getItem("selectedCertificate");
        let docsSelectedGuidString = sessionStorage.getItem('docsSelectedSignerGUIString');
        const enabledDocsIteration = docsSelectedGuidString !== null && docsSelectedGuidString !== "";
        /**Si entra en el if es que hay documentos guardado en sesión en formato string y debe pasarse a
             * array para que la tabla de docs lo reconozca y pueda marcarlos */
        if (enabledDocsIteration) {
            const vdocsSelectedGUI = docsSelectedGuidString.split(",");
            const filteredPendinSignatures = pendingDocuments.filter(doc => vdocsSelectedGUI.includes(doc.SignerDTO.signerGUI));
            
            filteredPendinSignatures.forEach(doc => {
                this.selectDocument(doc);
            })
        }
        let newDocumentState = { loading: false };
        if (selectedCertificate != null && !enabledDocsIteration) {
            newDocumentState.pendingSignatures = this.getFilteredPendindSignaturesByCertificate(pendingDocuments, selectedCertificate);
            newDocumentState.docCertSelected = selectedCertificate;
        }

        this.setState(newDocumentState);
    }

    renderDocument() {
        const _this = this;
        const { selectedDoc, renderedDoc, loadingMorePages } = this.state;
        const { pages, nextPage } = renderedDoc;
        const { pendingDocuments } = this.props;
        var documentsList = pendingDocuments;

        if (loadingMorePages || nextPage === -1) return;

        let signerGuid = selectedDoc.SignerDTO.SignerGUI;
        let docGuid = selectedDoc.DocGUI;
        const index = documentsList.findIndex((doc) => doc.SignerDTO.SignerGUI === signerGuid);
        if (index < documentsList.length - 1) _this.setState({ nextDocument: documentsList[index + 1] });

        if (pages.length > 0) this.setState({ loadingMorePages: true });

        documentRenderService.renderDocumentPages(docGuid, nextPage)
            .then(response => {
                const data = response.data;
                const pagesData = pages.concat(data.DocPageRendered);
                const totalPdfPagesNumber = data.NumberOfPages;
                const nextPageToRender = data.NextPage;
                
                _this.setState({
                    loadingMorePages: false,
                    loading: false,
                    renderedDoc: {
                        pages: pagesData,
                        nextPage: nextPageToRender,
                        totalPages: totalPdfPagesNumber
                    }
                });
            })
            .catch(function (error) {
                this.handleSessionExpiredError(error?.response?.status);
                _this.setState({ loadingMorePages: false, loading: false });
            });

        this.getDocumentInfoAndAttachments();
    }

    appendZero(value) {
        return value >= 10 ? value : `0${value}`;
    };

    formatDate(date) {
        let hours = date.getHours();

        const moment = hours >= 12 ? "PM" : "AM";
        hours = hours > 12 ? hours - 12 : hours;

        const formattedDate = [
            this.appendZero(date.getMonth() + 1),
            this.appendZero(date.getDate()),
            date.getFullYear()].join('/') + ' ' +
            [this.appendZero(hours),
            this.appendZero(date.getMinutes()),
            this.appendZero(date.getSeconds())].join(':') + ' ' + moment;

        return formattedDate;
    }

    getDocumentInfoAndAttachments() {
        const _this = this;
        const {selectedDoc} = this.state;
        let signerGUI = selectedDoc.SignerDTO.SignerGUI;

        _this.getDocumentInfo()
            .then(res => {
                if (res) {
                    var documentInfo = res;
                    var documentSigners = documentInfo.Signers;
                    for (var i = 0; i < documentSigners.length; i++) {
                        const fullUTCUploadTime = documentInfo.UploadTime + ' UTC';
                        const localeUploadTime = new Date(fullUTCUploadTime);
                        documentSigners[i].UploadTime = this.formatDate(localeUploadTime);
                        if (documentSigners[i].OperationTime) {
                            const fullUTCOperationTime = documentSigners[i].OperationTime + ' UTC';
                            const localeOperationTime = new Date(fullUTCOperationTime);
                            documentSigners[i].OperationTime = this.formatDate(localeOperationTime);
                        }
                    }
                    _this.getDocumentAttatchmentsList()
                        .then(res => {
                            if (res.length > 0) {
                                var docsAttached = res;
                                var requests = [];
                                var envURL = getVidCloudPrivUrl();
                                for (var i = 0; i < docsAttached.length; i++) {
                                    requests.push(axios.get(envURL + '/signatures/' + signerGUI + '/attachments/' + docsAttached[i].GUID + '/render', oauthService.oauthHeaders()));
                                }
                                var attachedDocsRenderArray = [];
                                axios.all(requests).then(axios.spread((...responses) => {
                                    for (var i = 0; i < responses.length; i++) {
                                        attachedDocsRenderArray.push(responses[i].data.DocPageRendered);
                                    }

                                    var attDocArray = [];
                                    if (docsAttached.length > 0) {
                                        attDocArray = docsAttached.map((docAtt, index) => {
                                            return <Nav.Item key={index} className="side-tab">
                                                <Nav.Link
                                                    eventKey={index}
                                                    onClick={() => this.setState({ docsAttachedRendered: attachedDocsRenderArray[index] })}
                                                    className="nav-link-2"
                                                >
                                                    <span className="side-tab-text"><img src={iconSeeAttachedDocs} alt="see attached docs" className="side-tab-icons" /> {docAtt.FileName}</span>
                                                </Nav.Link>
                                            </Nav.Item>;
                                        });
                                    }
                                    _this.setState({ documentInfo: documentInfo, documentSigners: documentSigners, documentAttatchmentsList: docsAttached, attDocArray: attDocArray, docsAttachedRendered: attachedDocsRenderArray[0] });
                                })).catch(errors => {
                                    console.log(errors);
                                });
                            }
                            else {
                                _this.setState({ documentInfo: documentInfo, documentSigners: documentSigners, documentAttatchmentsList: [], attDocArray: [], docsAttachedRendered: [] });
                            }
                        })
                        .catch(function (error) { });
                } else {
                    console.log('no user data');
                }
            })
            .catch(function (error) {
                _this.handleSessionExpiredError(error.response.status);
                _this.handlePreviouslyRejectedDocError(error);
                _this.setState({ loading: false });
            });
    }

    getDocumentInfo() {
        const _this = this;
        const { oauthTokenB64, selectedDoc } = this.state;
        const { pendingDocuments } = this.props;
        var config = { headers: { 'Authorization': 'Bearer ' + oauthTokenB64 } };
        var docGUI = selectedDoc.DocGUI;
        if (pendingDocuments.length === 1) {
            docGUI = pendingDocuments[0].DocGUI
        }
        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudPrivUrl() + '/documentinfo/' + docGUI, config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    _this.handleGenericError(error);
                    reject(error);
                });
        });

    }

    getDocumentAttatchmentsList() {
        const _this = this;
        const { oauthTokenB64, selectedDoc } = this.state;
        var config = { headers: { 'Authorization': 'Bearer ' + oauthTokenB64 } };
        var SignerGUI = selectedDoc.SignerDTO.SignerGUI


        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudPrivUrl() + '/signatures/' + SignerGUI + '/attachments/', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 403) {
                        return resolve([]);
                    }
                    _this.handleGenericError(error);
                    reject(error);
                });
        });
    }

    textFormatter() {
        return (
            <MdRefresh className="refresh-button" onClick={() => this.getPendingDocuments()} />
        );
    }

    closeFilterCertificateMsg() {
        this.setState({
            docCertSelected: null,
            docsSelectedSignerGUI: [],
            docsSelected: [],
            currentPageTable: 1,
        });
        sessionStorage.removeItem('docsSelectedSignerGUIString');
        sessionStorage.removeItem('selectedCertificate');
    }

    onPageChange = (page, sizePerPage) => {
        // this.setState({ currentPageTable: page })
        const newPaginationMeta = { 
            ...this.props.paginationMeta,
            PageNumber: page 
        };

        this.props.dispatch({
            type: DocumentsActionTypes.MODIFY_PAGINATION,
            payload: newPaginationMeta,
        });

        return {

        }
    }

    onSizePerPageChange = (sizePerPage) => {
        // this.setState({ currentSizePerPageTable: sizePerPage, currentPageTable: 1 });
        const newPaginationMeta = { 
            ...this.props.paginationMeta,
            PageSize: sizePerPage, 
            PageNumber: 1 
        };
        this.props.dispatch({
            type: DocumentsActionTypes.MODIFY_PAGINATION,
            payload: newPaginationMeta,
        });
    }

    shouldHideTagColumn = () => {
        let hiddenColumnTag = true;
        this.props.pendingDocuments.forEach((doc) => {
            if (doc.Tag !== "") {
                hiddenColumnTag = false;
                return;
            }
        });

        return hiddenColumnTag;
    }
    
    shouldHideSenderNameColumn = () => {
        let hiddenSenderName = true;
        this.props.pendingDocuments.forEach((doc) => {
            if (doc.SenderName !== "") {
                hiddenSenderName = false;
                return;
            }
        });

        return hiddenSenderName;
    }

    getCertificateAlias = () => {
        const { docCertSelected } = this.state;
        const { pendingDocuments } = this.props;

        if (docCertSelected) {
            const docWithCertificate = pendingDocuments.find((doc) => doc.SignerDTO?.CentralizedCertificateInfo?.Guid === docCertSelected);
            const certificate = docWithCertificate?.SignerDTO?.CentralizedCertificateInfo;

            return certificate?.Alias;
        }
    };

    handleBatchSignature = (validateDisclaimer = true) => {
        // Hide advanced search bar to prevent autofill bug from Chrome
        this.setState({ showAdvancedSearch: false});
        
        const { docsSelected } = this.state;

        const containsDisclaimer = docsSelected.some((doc) => doc.SignerDTO.DisclaimerInfo.Display);
        if (containsDisclaimer && validateDisclaimer) {
          this.getDisclaimerMessage();
            return;
        }
        this.setState({ showAdditionalSignaturesBatchDisclaimer: false });
        this.filterCertsByDocCert();
    };

    handleSorting(field, order) {
        const newOrdering = { 
            OrderType: order === "desc" ? "des" : "asc",
            OrderBy: field.includes("Certificate") ? "CentralizedCertificateAlias" : field,
        };

        this.props.dispatch({
            type: DocumentsActionTypes.MODIFY_SORTING,
            payload: newOrdering,
        });
    }

    getDisclaimerMessage() {
      const type = 'SIGNATURE_MULTIPOSITION_BATCH';
      const _this = this;
      const { oauthTokenB64, language } = this.state;
      const { docsSelected } = this.state;
      var docSelected;
      docsSelected.some((doc) => doc.SignerDTO.DisclaimerInfo.Display ? docSelected = doc : '');
      var config = {
        headers: {
          'Authorization': 'Bearer ' + oauthTokenB64
        }
      };

      const iso2Language = language.slice(0, 2);
  
      return new Promise(function (resolve, reject) {
        axios.get(getVidCloudPrivUrl() + '/pendingsignatures/' + docSelected.SignerDTO.SignerGUI + '/disclaimertext/' + type + '/' + iso2Language, config)
          .then(res => {
            resolve(res.data);
            const disclaimerData = res.data;
            _this.setState(
              { 
                showAdditionalSignaturesBatchDisclaimer: true,
                textAdditionalSignaturesDisclaimer: disclaimerData.Text,
                titleAdditionalSignaturesDisclaimer: disclaimerData.Title
              });
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
              _this.setState({ sessionExpired: true });
              _this.startTimer();
            }
            reject(error);
          });
      });
    }

    render() {
        const { t } = this.props;
        const { pendingDocuments } = this.props;
        const { viewDocument, loading, sessionExpired, redirect, time, language, renderedDoc,
            selectedDoc, rejectedDoc, signedDoc, redirecting, tokenExpired, showSignModal, certificatesList,
            showKeyVaultModal, showFirmaRemotaModal, showRedTrustModal, showSoftwareModal,
             loadingSignatureProcess, docsSelectedSignerGUI,
            certificateSelected, showError, errorType, errorData, certHidden, userIsLoggedByAuthCerts, showJumbotron, showNoPinConfirmationModal, documentInfo,
            documentSigners, documentAttatchmentsList, attDocArray, docsAttachedRendered,
            showAutofirmaModal, loadingSignatureProcessAutofirma, autofirmaPercentage, docsSigned, docsSelected, docCertSelected, 
            loadingMorePages, previouslyRejectedDoc, showAdvancedSearch, showAdditionalSignaturesBatchDisclaimer, 
            showWarningModal, selectedCertificate, currentDataAutofirma, titleAdditionalSignaturesDisclaimer, showUanatacaModalPin, showUanatacaModalOtp,
            textAdditionalSignaturesDisclaimer, showValidatedIdModalPin, showValidatedIdModalOtp, preventBatchModal } = this.state;
            const { pages, totalPages } = renderedDoc;

        if (redirect) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        if (userIsLoggedByAuthCerts) {
            return <Redirect to={{
                pathname: '/authcerts'
            }}
            />;
        }

        /**selectRow: opciones de la columna de checkbox*/
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: this.isSelectAllHidden(),
            clickToSelect: true,
            selectColumnStyle: { verticalAlign: "middle" },
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
            selected: docsSelectedSignerGUI
        };

        const columns = [
            {
                dataField: 'FileName',
                text: t('documents.document'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass1',
                style: { verticalAlign: 'middle' },
                onSort: this.handleSorting,
                formatter: (cellContent, row) => {
                    const attachment = row?.SignerDTO?.HasAttachment;
                    const additionalInfo = row?.Description;

                    return (
                      <div className="filename-container">
                        <label>{cellContent}</label>
                        <div className='filename-label-popup'>{cellContent}</div>
                        {attachment && (
                          <img src={attachmentIcon} alt="Attachment Icon" />
                        )}
                        {additionalInfo && (
                          <div className="hdg-label-info">
                            <img src={infoIcon} alt="Additional Info Icon" className='icon' />
                            <div className="hdg-label-popup">
                              {additionalInfo}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                }
            },
            {
                dataField: 'UploadTime',
                text: t('documents.sent'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass2',
                style: { verticalAlign: 'middle'},
                onSort: this.handleSorting,
                formatter: (cellContent, row) => {
                    const datetime = row?.UploadTime;
                    if (!datetime) return '';
                    const date = datetime.split(" ")[0];

                    return date;
                }
            },
            {
                dataField: 'Tag',
                text: t('documents.tag'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass2',
                style: { verticalAlign: 'middle'},
                onSort: this.handleSorting,
                hidden: this.shouldHideTagColumn(),
                formatter: (cellContent, row) => <TagsList tagString={row?.Tag}/>
            },
            {
                dataField: 'SenderName',
                text: t('documents.sender'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass2',
                style: { verticalAlign: 'middle'},
                onSort: this.handleSorting,
                hidden: this.shouldHideSenderNameColumn(),
                formatter: (_, row) => row?.SenderName ? row.SenderName : t('documents.document-cert-undef'),
            },
            {
                dataField: 'SignerDTO.CentralizedCertificateInfo.Guid',
                text: t('documents.cert-type'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass2',
                onSort: this.handleSorting,
                style: { verticalAlign: 'middle' },
                hidden: certHidden,
                formatter: (cellContent, row) => {
                    if (row?.SignerDTO.CentralizedCertificateInfo) {
                        if (row.SignerDTO.CentralizedCertificateInfo.Alias !== null) {
                            return (
                                <p>{row.SignerDTO.CentralizedCertificateInfo.Alias}</p>
                            );
                        }
                    }
                    return (
                        <p>{t('documents.document-cert-undef')}</p>
                    );
                }
            },
            {
                dataField: 'ActionButton',
                text: "",
                headerFormatter: this.textFormatter,
                align: 'center',
                headerAlign: 'left',
                onSort: this.handleSorting,
                isDummyField: true,
                style: { verticalAlign: 'middle' },
                headerClasses: 'documents-headerClass3',
                formatter: (cellContent, row, rowIndex) => {
                    return (
                        <Button
                            type="button"
                            id={row?.SignerDTO.SignerGUI}
                            className="btn btn-primary btn-table"
                            onClick={(e) => this.seeDocument(e, row)}
                        >
                            {t('documents.see')}
                        </Button>
                    );
                }
            }
        ];

        const paginationOptions = {
            custom: true,
            showTotal: true,
            totalSize: this.props.paginationMeta.Count,
            sizePerPage: this.props.paginationMeta.PageSize,
            page: this.props.paginationMeta.PageNumber,
            paginationSize: 3,
            firstPageText: t('general.first'),
            prePageText: t('general.pre'),
            nextPageText: t('general.next'),
            lastPageText: t('general.last'),
            nextPageTitle: t('general.first-page'),
            prePageTitle: t('general.pre-page'),
            firstPageTitle: t('general.next-page'),
            lastPageTitle: t('general.last-page'),
            sizePerPageList: [{
                text: '5', value: 5
            },{
                text: '10', value: 10
            }, {
                text: '30', value: 30
            }, {
                text: '50', value: 50
            },{
                text: '100', value: 100
            }],
            onPageChange: this.onPageChange,
            onSizePerPageChange: this.onSizePerPageChange
        };

        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <ToolkitProvider
                    keyField='SignerDTO.SignerGUI'
                    data={this.props.pendingDocuments}
                    columns={columns}
                    search={false}
                    bootstrap4
                >
                    {
                        toolkitprops => (
                            <div>
                                {docCertSelected && !showAdvancedSearch &&
                                    <div className="filter-list-container">
                                        <FilterCertificateMsg filter={t('documents.cert-type') + ": " + this.getCertificateAlias(docCertSelected)} close={() => this.closeFilterCertificateMsg()} />
                                    </div>
                                }
                                <BootstrapTable
                                    striped
                                    hover
                                    condensed
                                    onTableChange={() => {}}
                                    remote={{ sorting: true, pagination: true }}
                                    sort={{
                                        dataField: this.props.ordering.OrderBy,
                                        order: this.props.ordering.OrderType === "des" ? "desc" : "asc"
                                      }}
                                    bordered={false}
                                    selectRow={selectRow}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                <PaginationListStandalone
                    {...paginationProps}
                />
                <SizePerPageDropdownStandalone
                    {...paginationProps}
                    className={'page-dropdown'}
                />
            </div>
        );

        var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';

        return (
            <div className="documents-container">
                {rejectedDoc &&
                    <Toast
                        onClose={() => this.setState({ rejectedDoc: false })}
                        show={rejectedDoc}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.rejected')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.rejected-text')}</Toast.Body>
                    </Toast>
                }
                {signedDoc &&
                    <Toast
                        onClose={() => this.setState({ signedDoc: false })}
                        show={signedDoc}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.signed')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.signed-text')}</Toast.Body>
                    </Toast>
                }
                {redirecting &&
                    <Toast
                        onClose={() => this.setState({ redirecting: false })}
                        show={redirecting}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.exit')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.exit-text')}</Toast.Body>
                    </Toast>
                }
                {tokenExpired &&
                    <Toast
                        onClose={() => this.setState({ tokenExpired: false })}
                        show={tokenExpired}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.exit')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.exit-text2')}</Toast.Body>
                    </Toast>
                }
                {showError &&
                    <Toast
                        onClose={() => this.setState({ showError: false })}
                        show={showError}
                        animation={true}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 15
                        }}
                    >
                        <Toast.Header
                            closeButton={true}
                        >
                            <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                        </Toast.Header>
                        <Toast.Body>{errorData}</Toast.Body>
                    </Toast>
                }
                {previouslyRejectedDoc &&
                    <CustomToast handleClose={() => {
                            this.getPendingDocuments();
                            this.setState({
                                previouslyRejectedDoc: false,
                                viewDocument: false
                            })}
                        }
                            show={previouslyRejectedDoc}
                            title={t('general.error')}
                            message={t('general.rejected')}
                            delay={null} />
                }
                {sessionExpired &&
                    <div className="toast-container">
                        <Toast
                            show={sessionExpired}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                                <small>{timeText}</small>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('general.session-expired')}</p>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    id="sign-all-docs"
                                    onClick={() => this.doLogOut()}
                                >
                                    {t('general.accept')}
                                </Button>
                            </Toast.Body>
                        </Toast>
                    </div>
                }
                <Header />
                {(!viewDocument && showJumbotron) &&
                    <Jumbotron>
                        <MdClose className="close-jumbotron" onClick={() => this.setState({ showJumbotron: !showJumbotron })} />
                        <div className="jumbotron-content">
                            <img src={warning} alt="Warning icon"/>
                            <p>
                                {t('documents.pending')}. {t('documents.pending-text')}
                            </p>
                        </div>
                    </Jumbotron>
                }
                {
                    loadingMorePages && 
                        <div className="loading-more-container">
                            <LoadingCircle/>
                        </div>
                }
                {(!loading && !viewDocument && pendingDocuments.length > 0) &&
                    <div className="documents-table-container">
                        <CustomModal 
                                    text={textAdditionalSignaturesDisclaimer}
                                    title={titleAdditionalSignaturesDisclaimer}
                                    show={showAdditionalSignaturesBatchDisclaimer}
                                    handleHide={() => this.setState({ showAdditionalSignaturesBatchDisclaimer: false })}
                                    handleConfirm={() => this.handleBatchSignature(false)}
                                />
                        <Modal show={showSignModal} onHide={() => this.handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('documents.cert-type-selection')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ListGroup defaultActiveKey="#link1">
                                    {certificatesList}
                                </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="reject" onClick={() => this.handleClose()}>
                                    {t('general.cancel')}
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <KeyVaultSignature
                          show={showKeyVaultModal}
                          onClose={this.handleClose}
                          onSuccess={this.handleModalSignatureSuccess}
                          certificateSelected={certificateSelected} />

                        <FirmaRemota 
                          show={showFirmaRemotaModal}
                          onClose={this.handleClose}
                          onSuccess={this.handleModalSignatureSuccess}
                          certificateSelected={certificateSelected} />

                        <RedTrustSignature 
                            show={showRedTrustModal}
                            onClose={this.handleClose}
                            onSuccess={this.handleModalSignatureSuccess} />

                        <UanatacaSignature 
                            showModalPin={showUanatacaModalPin}
                            showModalOtp={showUanatacaModalOtp}
                            onClose={this.handleClose}
                            onSuccessOtp={this.handleModalSignatureOtpSuccess}
                            onSuccess={this.handleModalSignatureSuccess} />

                        <ValidatedIdSignature
                            showModalPin={showValidatedIdModalPin}
                            showModalOtp={showValidatedIdModalOtp}
                            onClose={this.handleClose}
                            onSuccessOtp={this.handleModalSignatureValidatedIdSuccess}
                            onSuccess={this.handleModalSignatureSuccess} />

                        <SoftwareSignature
                            show={showSoftwareModal}
                            onClose={this.handleClose}
                            onSuccess={this.handleModalSignatureSuccess} />
                        
                        <SwisscomSignature 
                            parentState={this.state} 
                            onClose={this.handleClose} 
                            onSuccessSignDocSelection={() => this.setState({
                                showSwisscomModal: false,
                                showSwisscomConfirmationModal: true,
                                loadingSwisscomRequest: true
                            }, () => this.signDocSelection(SWISSCOM))}
                            onSuccess={() => this.setState({ 
                                loadingSwisscomCheck: true, 
                                swisscomError: '' }, 
                                () => this.completeSwisscomSignature())} />

                        <AutofirmaSignature show={showAutofirmaModal}
                            onClose={this.handleClose}
                            loadingSignatureProcessAutofirma={loadingSignatureProcessAutofirma}
                            currentDataAutofirma={currentDataAutofirma}
                            docsSelected={docsSelected}
                            autofirmaPercentage={autofirmaPercentage} />

                        <NebulaSignature 
                            onChangeFormControl={(e) => this.handleAuthenticatorChange(e)} 
                            onSendAuthenticator={this.sendAuthenticator}
                            parentState={this.state}
                            authenticators={this.authenticators}
                            onClose={this.handleClose}
                            onChallengeChange={e => this.setState({ NBChallenge: e.target.value })}
                            onSendChallenge={this.sendChallenge}
                            onFocusInput={(input) => { this.focusInput(input); }}
                            onCertificatePinChange={e => this.setState({ certificateNebulaPin: e.target.value })}
                            onSignDoc={() => this.setState({ loadingSignatureProcess: true, showNebulaModal: false }, () => this.signDocSelection(NEBULA))}
                            />
                        
                        <Modal show={showNoPinConfirmationModal} onHide={() => this.handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('documents.no-pin-title')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {t('documents.no-pin-text')}
                            </Modal.Body>
                            <Modal.Footer>
                                <div>
                                    <Button
                                        className="reject"
                                        onClick={() => this.handleClose()}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => this.handleNoPinDocSignature()}
                                        style={{ marginLeft: 20 }}
                                    >
                                        {t('general.confirm')}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        
                        <Modal show={showWarningModal} onHide={() => this.handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('documents.warning')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {selectedCertificate} {t('documents.certificate-unavailable')}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="reject" onClick={() => this.handleClose()}>
                                    {t('general.cancel')}
                                </Button>
                                <Button variant="primary" onClick={() => this.setState({ showWarningModal: false }, () => this.handleClose())}>
                                    {t('general.confirm')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        
                        <Modal show={preventBatchModal} onHide={() => this.setState({ preventBatchModal: false })}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('documents.warning')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {selectedCertificate} {t('documents.certificate-unavailable')}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => this.setState({ preventBatchModal: false })}>
                                    {t('general.confirm')}
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <SigningProcessModal
                            showModal={loadingSignatureProcess}
                            showProcess={true}
                            signedDocs={docsSigned}
                            selectedDocs={docsSelected.length} />

                        <SearchBar
                            updateState={(newState) => this.setState(newState)}
                            selectedCert={docCertSelected}
                            showTag={!this.shouldHideTagColumn()}
                            showSenderNameColumn={!this.shouldHideSenderNameColumn()}
                            showCertificate={!certHidden}
                            docsSelectedSignerGUI={docsSelectedSignerGUI}
                            showAdvancedSearch={showAdvancedSearch}
                            setShowAdvancedSearch={(showAdvancedSearch) => this.setState({showAdvancedSearch})}
                        />

                        <PaginationProvider
                            pagination={paginationFactory(paginationOptions)}>
                            {contentTable}
                        </PaginationProvider>
                        
                        <div className='button-container'>
                            <Button
                                type="button"
                                disabled={docsSelectedSignerGUI.length === 0 || null}
                                className={docsSelectedSignerGUI.length === 0 || null ? "btn btn-primary button-disabled" : "btn btn-primary"}
                                id="sign-all-docs"
                                onClick={() => this.handleBatchSignature(true)}
                            >
                                {t('documents.sign-selected')}
                            </Button>
                        </div>
                    </div>
                }
                {(!loading && !viewDocument && pendingDocuments.length === 0) &&
                    <EmptyDocsPanel onClickGetPendingDocuments={() => this.getPendingDocuments()} />
                }
                {loading &&
                    <LoadingCircle centerVertically={true} />
                }
                {(viewDocument && pages.length > 0) &&
                    <DocumentPreview
                        renderedDoc={pages}
                        selectedDoc={selectedDoc}
                        pendingSignatures={pendingDocuments}
                        documentInfo={documentInfo}
                        documentSigners={documentSigners}
                        documentAttatchmentsList={documentAttatchmentsList}
                        attDocArray={attDocArray}
                        docsAttachedRendered={docsAttachedRendered}
                        viewDocumentHandler={this.viewDocumentHandler}
                        showRejectedDocHandler={this.showRejectedDocHandler}
                        showSignedDocHandler={this.showSignedDocHandler}
                        totalPages={totalPages}
                        renderDocument={this.renderDocument}
                        handlePreviouslyRejectedDocError={this.handlePreviouslyRejectedDocError}
                        seeNextDocument={this.seeNextDocument}
                        checkedDocs={docsSelectedSignerGUI}
                        updateSelectedDocs={this.handleOnSelect}
                    />
                }
            </div>
        );
    }

    componentDidMount() {
        Utils.appendScript("/assets/js/autoscript.js");
        Utils.appendScript("/assets/js/forge.min.js");
        this.getClientData();

        ThemeService.setTheme();

        var authCerts = sessionStorage.getItem('authCerts');
        if (authCerts !== null) {
            this.setState({ userIsLoggedByAuthCerts: true });
        }
        this.setState({ oauthTokenB64: sessionStorage.getItem('oauthTokenB64') });
        this.getPendingDocuments();
        this.loadViDMobileUserCertificateList();
    }

    componentDidUpdate(prevProps) {
        const { filters: oldFilters, paginationMeta: oldPagination, ordering: oldOrdering } = prevProps;
        const { filters, paginationMeta, ordering } = this.props;

        //Detect changes
        if (
            JSON.stringify(oldPagination) !== JSON.stringify(paginationMeta) || 
            JSON.stringify(oldOrdering) !== JSON.stringify(ordering) ||
            JSON.stringify(oldFilters) !== JSON.stringify(filters)
            ) this.getPendingDocuments();
        
    }
}

const mapStateToProps = (state) => {
    return { 
        pendingDocuments: state.documents.filteredDocuments,
        filters: state.documents.filters,
        paginationMeta: state.documents.paginationMeta,
        ordering: state.documents.ordering,
    };
};

export default connect(mapStateToProps)(withTranslation()(Documents));
